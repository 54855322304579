import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Homepage from './pages/Home';
import PublicHomepage from './pages/PublicHomepage';
import MapPageWrapper from './pages/MapPageWrapper';
import Recommend from './pages/Recommend';
import PublicRecommend from './pages/PublicRecommend';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import About from './pages/About';
import Community from './pages/Community';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import PlaceProfile from './pages/PlaceProfile';
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from './pages/ForgotPassword';
import ErrorBoundary from './components/ErrorBoundary';
import { optimizePerformance } from './utils/performanceOptimizations';
import { AuthProvider, useAuth } from './context/AuthContext';
import { FavoritesProvider } from './context/FavoritesContext';
import { LocationProvider, useLocationContext } from './context/LocationContext';
import { UserTypeProvider } from './context/UserTypeContext';
import { suppressResizeObserverErrors } from './errorSuppress';
import BoeiChat from './components/BoeiChat';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound';
import Roadmap from './pages/Roadmap';

function App() {
  const resizeObserverLoopErr = (err) => {
    if (err.message === 'ResizeObserver loop limit exceeded') {
      return;
    }
    console.error(err);
  };

  window.addEventListener('error', resizeObserverLoopErr);
  window.addEventListener('unhandledrejection', resizeObserverLoopErr);
  suppressResizeObserverErrors();

  return (
    <Router>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </Router>
  );
}

function AppContent() {
  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate('/');
  };

  return (
    <AuthProvider onSignOut={handleSignOut}>
      <LocationProvider>
        <FavoritesProvider>
          <UserTypeProvider>
            <Toaster 
              position="bottom-center"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{
                bottom: 40,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 9999,
              }}
              toastOptions={{
                className: '',
                duration: 3000,
                style: {
                  background: '#363636',
                  color: '#fff',
                  padding: '16px',
                  borderRadius: '8px',
                  fontSize: '14px',
                  maxWidth: '500px',
                  textAlign: 'center',
                },
                success: {
                  duration: 3000,
                  style: {
                    background: '#2e7d32',
                  },
                  iconTheme: {
                    primary: '#fff',
                    secondary: '#2e7d32',
                  },
                },
                error: {
                  duration: 4000,
                  style: {
                    background: '#d32f2f',
                  },
                  iconTheme: {
                    primary: '#fff',
                    secondary: '#d32f2f',
                  },
                },
                loading: {
                  duration: 3000,
                  style: {
                    background: '#1976d2',
                  },
                },
              }}
            />
            <AppRoutes />
            <BoeiChat />
          </UserTypeProvider>
        </FavoritesProvider>
      </LocationProvider>
    </AuthProvider>
  );
}

function AppRoutes() {
  const { user } = useAuth();
  const { location } = useLocationContext();
  const navigate = useNavigate();
  const currentLocation = useLocation();

  React.useEffect(() => {
    if (user && location.country_code) {
      const countryCode = location.country_code || 'ca';
      if (currentLocation.pathname === '/' || currentLocation.pathname === '/signin') {
        navigate(`/${countryCode}`, { replace: true });
      }
    }
  }, [user, location, navigate, currentLocation]);

  useEffect(() => {
    optimizePerformance();
  }, []);

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={user ? <Navigate to={`/${location.country_code || 'ca'}`} /> : <PublicHomepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/community" element={<Community />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/map" element={<MapPageWrapper />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/recommend" element={<PublicRecommend />} />

      {/* Protected routes */}
      <Route path="/:countryCode" element={
        <ProtectedRoute>
          <Homepage />
        </ProtectedRoute>
      } />
      <Route path="/:countryCode/map" element={
        <ProtectedRoute>
          <MapPageWrapper />
        </ProtectedRoute>
      } />
      <Route path="/:countryCode/recommend" element={
        <ProtectedRoute>
          <Recommend />
        </ProtectedRoute>
      } />
      <Route path="/:countryCode/dashboard" element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      } />
      <Route path="/:countryCode/:province/place/:identifier" element={
        <ProtectedRoute>
          <PlaceProfile />
        </ProtectedRoute>
      } />

      {/* 404 Not Found route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;