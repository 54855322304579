import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProvinceUrlSync } from '../hooks/useProvinceUrlSync';
import { useLocationContext } from '../context/LocationContext';
import MapPage from './MapPage';

const MapPageWrapper = () => {
  const [selectedProvince, setSelectedProvince] = useState('');
  const { location } = useLocationContext();
  const { countryCode } = useParams();

  const syncedProvince = useProvinceUrlSync(selectedProvince, setSelectedProvince);

  useEffect(() => {
    if (location.province && !selectedProvince) {
      setSelectedProvince(location.province);
    }
  }, [location.province, selectedProvince]);

  const handleProvinceChange = (province) => {
    setSelectedProvince(province === 'All Provinces' ? '' : province);
  };

  return (
    <MapPage
      initialProvince={syncedProvince}
      onProvinceChange={handleProvinceChange}
      countryCode={countryCode}
    />
  );
};

export default MapPageWrapper;