import React, { useState } from 'react';

const navigation = {
  company: [
    { name: 'Map', href: 'https://altgrocery.io/map?province=Ontario' },
    { name: 'Recommend', href: 'https://altgrocery.io/recommend' },
    { name: 'About', href: 'https://altgrocery.io/about' },
    { name: 'Roadmap', href: 'https://altgrocery.io/roadmap' },
  ],
  legal: [
    { name: 'Privacy', href: 'https://altgrocery.io/privacy' },
    { name: 'Terms', href: 'https://www.altgrocery.io/terms' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/altgrocery',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/altgrocery_',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

const countries = [
  { name: 'Canada', code: 'CA' },
  { name: 'United States', code: 'US' },
  { name: 'Australia', code: 'AU' },
];

function Footer({ customStyle, textAlignLeft }) {
  const currentYear = new Date().getFullYear();
  const [selectedCountry, setSelectedCountry] = useState('CA');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    // Here you can add logic to change the site based on the selected country
  };

  return (
    <footer className="bg-white" style={customStyle}>
      <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ maxWidth: '97rem' }}>
        <div className="py-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="space-y-8">
            <a href="https://altgrocery.io" className="flex items-center">
              <img
                src="/altgrocery3.svg"
                alt="AltGrocery Logo"
                className="h-8 w-auto"
              />
            </a>
            <p className="text-sm leading-6 text-gray-600">
              Helping small grocers and farmers connect with their communities. Discover and recommend independent grocers and markets, all powered by you.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition-all duration-200 ease-in-out transform hover:scale-110">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">Company</h3>
            <ul className="mt-6 space-y-4">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900 transition-all duration-200 ease-in-out transform hover:scale-105 inline-block">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
            <ul className="mt-6 space-y-4">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900 transition-all duration-200 ease-in-out transform hover:scale-105 inline-block">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-900/10 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p className="text-xs leading-5 text-gray-500">
                &copy; {currentYear} AltGrocery, a division of{' '}
                <span className="text-gray-600">
                  Pixel Mixel Inc.
                </span>{' '}
                All rights reserved.
              </p>
            </div>
            <div className="flex items-center">
              <div className="relative">
                <select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  className="text-xs leading-5 text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 pl-3 pr-8 py-1 appearance-none"
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                  <svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                  </svg>
                </div>
              </div>
              <span className="ml-2 text-xs leading-5 text-gray-500">Coming soon</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
