import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const insertRecommendation = async (data) => {
  const { data: result, error } = await supabase
    .from('recommendations')
    .insert([data])
    .select()
    .headers({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    });

  return { data: result, error };
};