import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement);

const COLORS = {
  PIE: ['rgba(255, 99, 132, 0.8)', 'rgba(54, 162, 235, 0.8)', 'rgba(255, 206, 86, 0.8)'],
  PIE_BORDER: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
  BAR: ['rgba(75, 192, 192, 0.8)', 'rgba(153, 102, 255, 0.8)'],
  BAR_BORDER: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
  LINE: 'rgba(255, 159, 64, 0.8)',
  LINE_BORDER: 'rgba(255, 159, 64, 1)',
};

function DashboardOverview({ user }) {
  // Dummy data for the overview
  const favoriteData = useMemo(() => [
    { name: 'Place A', count: 65 },
    { name: 'Place B', count: 59 },
    { name: 'Place C', count: 80 },
  ], []);

  const viewData = useMemo(() => ({
    total: 350,
    unique: 300,
  }), []);

  const activityData = useMemo(() => [
    { date: '2023-05-01', count: 10 },
    { date: '2023-05-02', count: 15 },
    { date: '2023-05-03', count: 8 },
    { date: '2023-05-04', count: 12 },
    { date: '2023-05-05', count: 20 },
  ], []);

  const pieChartData = useMemo(() => ({
    labels: favoriteData.map(place => place.name),
    datasets: [
      {
        data: favoriteData.map(place => place.count),
        backgroundColor: COLORS.PIE,
        borderColor: COLORS.PIE_BORDER,
        borderWidth: 1,
      },
    ],
  }), [favoriteData]);

  const barChartData = useMemo(() => ({
    labels: ['Total Views', 'Unique Visitors'],
    datasets: [
      {
        label: 'Profile Views',
        data: [viewData.total, viewData.unique],
        backgroundColor: COLORS.BAR,
        borderColor: COLORS.BAR_BORDER,
        borderWidth: 1,
      },
    ],
  }), [viewData]);

  const lineChartData = useMemo(() => ({
    labels: activityData.map(item => item.date),
    datasets: [
      {
        label: 'Daily Activity',
        data: activityData.map(item => item.count),
        fill: false,
        backgroundColor: COLORS.LINE,
        borderColor: COLORS.LINE_BORDER,
      },
    ],
  }), [activityData]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  if (!user) {
    return <div className="flex-1 p-4">Loading...</div>;
  }

  return (
    <div className="flex flex-col flex-1 max-w-full">
      <h2 className="text-2xl font-bold mb-6">Welcome, {user.email}</h2>
      <div className="flex flex-wrap -mx-2 xl:-mx-4">
        <div className="w-full md:w-1/2 xl:w-1/3 px-2 xl:px-4 mb-4">
          <div className="bg-white p-4 rounded shadow h-full">
            <h3 className="text-lg font-semibold mb-2">Favorites by Place</h3>
            <div className="h-64 md:h-80 xl:h-96">
              <Pie data={pieChartData} options={chartOptions} />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 px-2 xl:px-4 mb-4">
          <div className="bg-white p-4 rounded shadow h-full">
            <h3 className="text-lg font-semibold mb-2">Profile Views</h3>
            <div className="h-64 md:h-80 xl:h-96">
              <Bar data={barChartData} options={chartOptions} />
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/3 px-2 xl:px-4 mb-4">
          <div className="bg-white p-4 rounded shadow h-full">
            <h3 className="text-lg font-semibold mb-2">Daily Activity</h3>
            <div className="h-64 md:h-80 xl:h-96">
              <Line data={lineChartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardOverview.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
};

export default DashboardOverview;