import React from 'react';
import TagBadge from './TagBadge';

function TagBadgeList({ filters, onTagRemove }) {
  const activeTags = Object.entries(filters)
    .filter(([key, value]) => value && key !== 'province')
    .map(([key, value]) => ({ key, label: value }));

  return (
    <div className="flex flex-wrap gap-2">
      {activeTags.map(tag => (
        <TagBadge
          key={tag.key}
          label={tag.label}
          onRemove={() => onTagRemove(tag.key)}
        />
      ))}
    </div>
  );
}

export default TagBadgeList;