import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Announcement from '../components/Announcement';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Filter } from 'bad-words';
import toast from 'react-hot-toast';

// Initialize the Supabase client with anon key
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

// Initialize the profanity filter
const filter = new Filter();

const getTitleFontSize = (title) => {
  if (title.length <= 24) return '1.125rem';
  if (title.length <= 50) return '1rem';
  if (title.length <= 75) return '0.875rem';
  return '0.75rem';
};

const getFirstLineWithEllipsis = (text, maxLength = 60) => {
  const firstLine = text.split('\n')[0];
  if (firstLine.length <= maxLength) {
    return firstLine.length < text.length ? firstLine + '...' : firstLine;
  }
  return firstLine.substring(0, maxLength - 3) + '...';
};

const RoadmapItem = ({ id, title, description, votes, onVote, status, created_at }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [localVotes, setLocalVotes] = useState(votes);
  const titleFontSize = getTitleFontSize(title);
  const truncatedDescription = getFirstLineWithEllipsis(description);

  useEffect(() => {
    const votedItems = JSON.parse(localStorage.getItem('votedItems') || '[]');
    setHasVoted(votedItems.includes(id));
  }, [id]);

  const handleVoteClick = async (e) => {
    e.stopPropagation();
    if (hasVoted) {
      toast.error('You have already voted for this item.');
      return;
    }
    const success = await onVote(id);
    if (success) {
      setHasVoted(true);
      setLocalVotes(localVotes + 1);
      const votedItems = JSON.parse(localStorage.getItem('votedItems') || '[]');
      localStorage.setItem('votedItems', JSON.stringify([...votedItems, id]));
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm mb-4 transition-all duration-300 hover:shadow-md overflow-hidden">
      <div 
        className="p-4 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-semibold text-gray-800 leading-tight" style={{ 
            fontSize: titleFontSize,
            maxWidth: '90%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {title}
          </h3>
          <ChevronDownIcon
            className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isExpanded ? 'transform rotate-180' : ''}`}
          />
        </div>
        {!isExpanded && (
          <p className="text-sm text-gray-600 mb-2">{truncatedDescription}</p>
        )}
      </div>
      {isExpanded && (
        <div className="px-4 pb-4">
          <p className="text-sm text-gray-600 mb-3">{description}</p>
          <div className="flex items-center justify-between">
            <span className="text-xs font-medium text-gray-500">Votes: {localVotes}</span>
            {status !== 'completed' && (
              <button
                onClick={handleVoteClick}
                disabled={hasVoted}
                className={`${
                  hasVoted ? 'bg-gray-400 cursor-not-allowed' : 'bg-black hover:bg-gray-800'
                } text-white px-3 py-1 rounded-full text-xs font-medium transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50`}
              >
                {hasVoted ? 'Voted' : 'Vote'}
              </button>
            )}
          </div>
          <div className="text-xs text-gray-500 mt-2">
            Created: {new Date(created_at).toLocaleDateString()}
          </div>
        </div>
      )}
    </div>
  );
};

const RoadmapColumn = ({ title, items, bgColor, onVote }) => {
  const [showAll, setShowAll] = useState(false);
  const visibleItems = showAll ? items : items.slice(0, 5);
  const hasMore = items.length > 5;

  return (
    <div className={`${bgColor} p-4 rounded-lg`}>
      <h2 className="text-xl font-bold mb-4 text-gray-800">{title}</h2>
      <div className={`space-y-4 ${showAll ? 'max-h-[calc(100vh-400px)] overflow-y-auto pr-2' : ''}`}>
        {visibleItems.map((item) => (
          <RoadmapItem key={item.id} {...item} onVote={onVote} />
        ))}
      </div>
      {hasMore && (
        <button
          onClick={() => setShowAll(!showAll)}
          className="mt-4 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900"
        >
          {showAll ? (
            <>
              <ChevronUpIcon className="w-5 h-5 mr-1" />
              Show Less
            </>
          ) : (
            <>
              <ChevronDownIcon className="w-5 h-5 mr-1" />
              Show More
            </>
          )}
        </button>
      )}
    </div>
  );
};

const SuggestionBox = ({ onSubmit }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const titleMaxLength = 100;
  const descriptionMaxLength = 500;

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    if (newTitle.length <= titleMaxLength) {
      setTitle(newTitle);
    }
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= descriptionMaxLength) {
      setDescription(newDescription);
      setDescriptionCharCount(newDescription.length);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(title, description);
    setTitle('');
    setDescription('');
    setDescriptionCharCount(0);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8 bg-white p-6 rounded-lg border border-gray-200">
      <h2 className="text-xl font-bold mb-4 text-gray-800">Suggest a Feature</h2>
      <div className="mb-3">
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          className="w-full p-2 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          placeholder="Feature title"
          required
          maxLength={titleMaxLength}
        />
      </div>
      <div className="mb-3">
        <textarea
          value={description}
          onChange={handleDescriptionChange}
          className="w-full p-2 text-sm border border-gray-300 rounded-lg mb-1 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          rows="3"
          placeholder="Describe your feature suggestion..."
          required
          maxLength={descriptionMaxLength}
        ></textarea>
        <div className="text-xs text-gray-500 text-right">
          {descriptionCharCount}/{descriptionMaxLength} characters
        </div>
      </div>
      <button
        type="submit"
        className="bg-green-500 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-green-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
      >
        Submit Suggestion
      </button>
      <p className="mt-2 text-xs text-gray-500">
        Note: Your suggestion will be reviewed before being published.
      </p>
    </form>
  );
};

const Roadmap = () => {
  const [roadmapItems, setRoadmapItems] = useState({
    suggested: [],
    planned: [],
    inProgress: [],
    completed: []
  });

  useEffect(() => {
    fetchRoadmapItems();
  }, []); // Empty dependency array to ensure it only runs once on mount

  const fetchRoadmapItems = async () => {
    console.log('Fetching roadmap items...');
    try {
      const { data, error } = await supabase
        .from('roadmap')
        .select('*')
        .neq('status', 'pending') // Exclude pending items
        .order('created_at', { ascending: false });

      if (error) throw error;

      console.log('Fetched roadmap items:', data);
      const categorizedItems = {
        suggested: data.filter(item => item.status === 'suggested'),
        planned: data.filter(item => item.status === 'planned'),
        inProgress: data.filter(item => item.status === 'in_progress'),
        completed: data.filter(item => item.status === 'completed')
      };
      setRoadmapItems(categorizedItems);
    } catch (error) {
      console.error('Error fetching roadmap items:', error);
      toast.error('Failed to fetch roadmap items. Please try again later.');
    }
  };

  const handleVote = async (itemId) => {
    console.log(`Attempting to vote for item ${itemId}`);
    try {
      // Check if the user has already voted using local storage
      const votedItems = JSON.parse(localStorage.getItem('votedItems') || '[]');
      if (votedItems.includes(itemId)) {
        console.log(`User has already voted for item ${itemId}`);
        toast.error('You have already voted for this item.');
        return false;
      }

      // Fetch the current item to get its vote count
      const { data: currentItem, error: fetchError } = await supabase
        .from('roadmap')
        .select('votes')
        .eq('id', itemId)
        .single();

      if (fetchError) throw fetchError;

      if (!currentItem) {
        console.error('Item not found');
        throw new Error('Item not found');
      }

      // Increment the vote count
      const newVoteCount = (currentItem.votes || 0) + 1;

      // Update the vote count in the database
      const { data, error } = await supabase
        .from('roadmap')
        .update({ votes: newVoteCount })
        .eq('id', itemId)
        .select();

      if (error) throw error;

      if (!data || data.length === 0) {
        console.error('No data returned from update operation');
        throw new Error('Failed to update vote');
      }

      console.log('Vote updated successfully:', data);

      // Update the local state
      setRoadmapItems(prevItems => {
        const updatedItems = { ...prevItems };
        for (const category in updatedItems) {
          updatedItems[category] = updatedItems[category].map(item => 
            item.id === itemId ? { ...item, votes: newVoteCount } : item
          );
        }
        return updatedItems;
      });

      // Update local storage to prevent duplicate votes
      localStorage.setItem('votedItems', JSON.stringify([...votedItems, itemId]));

      toast.success('Vote recorded successfully!');
      return true;
    } catch (error) {
      console.error('Error in handleVote:', error);
      toast.error('Failed to update vote. Please try again.');
      return false;
    }
  };

  const handleSuggestion = async (title, description) => {
    console.log('Submitting new suggestion:', { title, description });
    
    // Check for profanity
    if (filter.isProfane(title) || filter.isProfane(description)) {
      toast.error('Your suggestion contains inappropriate language. Please revise and try again.');
      return;
    }
    
    try {
      const { data, error } = await supabase
        .from('roadmap')
        .insert([
          { title, description, status: 'pending', votes: 0 }
        ])
        .select();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      if (!data || data.length === 0) {
        console.error('No data returned from insert operation');
        throw new Error('No data returned from insert operation');
      }

      console.log('Suggestion added successfully:', data);
      toast.success('Thank you for your suggestion. It will be reviewed before being published.');
      // We don't need to refresh the roadmap items here as pending items are not displayed
    } catch (error) {
      console.error('Error adding suggestion:', error);
      if (error.message) {
        console.error('Error message:', error.message);
      }
      if (error.details) {
        console.error('Error details:', error.details);
      }
      toast.error(`Failed to add suggestion. Please try again. Error: ${error.message || 'Unknown error'}`);
    }
  };

  return (
    <>
      <Announcement />
      <div className="bg-white min-h-screen">
        <Header />
        <main className="py-12">
          <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ maxWidth: '97rem' }}>
            <h1 className="text-4xl font-bold text-gray-900 mb-6 text-center">Product Roadmap</h1>
            <p className="text-lg text-gray-600 mb-12 text-center mx-auto leading-relaxed">
             As a crowdsourced platform, we value your input and believe in building this site together. Here's a glimpse into what we're working on and what's coming next. <br></br>Share your thoughts and vote on the features you'd like to see prioritized.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <RoadmapColumn title="Community Suggested" items={roadmapItems.suggested} bgColor="bg-purple-50" onVote={handleVote} />
              <RoadmapColumn title="Planned" items={roadmapItems.planned} bgColor="bg-blue-50" onVote={handleVote} />
              <RoadmapColumn title="In Progress" items={roadmapItems.inProgress} bgColor="bg-yellow-50" onVote={handleVote} />
              <RoadmapColumn title="Completed" items={roadmapItems.completed} bgColor="bg-green-50" onVote={handleVote} />
            </div>
            <SuggestionBox onSubmit={handleSuggestion} />
          </div>
        </main>
        <Footer 
          customStyle={{ 
            maxWidth: '97rem', 
            margin: '0 auto'
          }} 
          textAlignLeft={true}
        />
      </div>
    </>
  );
};

export default Roadmap;