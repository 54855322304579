import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { supabase } from '../config/supabaseClient';

export const useMapFilters = (initialFilters, onFilterChange, categories, subCategories) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    ...initialFilters,
    province: searchParams.get('province') || initialFilters.province,
    city: searchParams.get('city') || initialFilters.city,
    category: searchParams.get('category') || initialFilters.category,
    subCategory: searchParams.get('subCategory') || initialFilters.subCategory,
  });
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [autoDetectEnabled, setAutoDetectEnabled] = useState(true);

  const updateUrl = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });
    setSearchParams(newSearchParams);
  }, [filters, setSearchParams, searchParams]);

  useEffect(() => {
    updateUrl();
  }, [updateUrl]);

  const fetchProvinces = async () => {
    try {
      const { data, error } = await supabase.from('places').select('province');
      if (error) throw error;
      const uniqueProvinces = [...new Set(data.map(item => item.province))].sort();
      setProvinces(uniqueProvinces);
    } catch (error) {
      console.error('Error fetching provinces:', error);
    }
  };

  const fetchCities = useCallback(async (province) => {
    try {
      const { data, error } = await supabase
        .from('places')
        .select('city')
        .eq('province', province);
      if (error) throw error;
      const uniqueCities = [...new Set(data.map(item => item.city))].sort();
      setCities(uniqueCities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }, []);

  const handleProvinceChange = useCallback(
    async (province) => {
      const newFilters = {
        ...filters,
        province: province === 'All Provinces' ? '' : province,
        city: '',
      };
      setFilters(newFilters);
      setAutoDetectEnabled(false);
      if (province !== 'All Provinces') {
        await fetchCities(province);
      } else {
        setCities([]);
      }
      onFilterChange(newFilters);
    },
    [filters, onFilterChange, fetchCities]
  );

  const handleCityChange = useCallback(
    (city) => {
      const newFilters = { ...filters, city };
      setFilters(newFilters);
      setAutoDetectEnabled(false);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange]
  );

  const handleCategoryChange = useCallback(
    (categoryName) => {
      const newFilters = {
        ...filters,
        category: categoryName,
        subCategory: '',
      };
      setFilters(newFilters);
      setAutoDetectEnabled(false);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange]
  );

  const handleSubCategoryChange = useCallback(
    (subCategoryName) => {
      const newFilters = { ...filters, subCategory: subCategoryName };
      setFilters(newFilters);
      setAutoDetectEnabled(false);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange]
  );

  const handleTagRemove = useCallback(
    (tag) => {
      console.log('handleTagRemove called with tag:', tag);
      console.log('Current filters:', filters);

      const newFilters = { ...filters };

      if (filters.category === tag) {
        newFilters.category = '';
        newFilters.subCategory = '';
      } else if (filters.subCategory === tag) {
        newFilters.subCategory = '';
      } else if (filters.city === tag) {
        newFilters.city = '';
      }

      console.log('New filters after removal:', newFilters);

      setFilters(newFilters);
      setAutoDetectEnabled(false);
      onFilterChange(newFilters);
    },
    [filters, onFilterChange]
  );

  const resetFilters = useCallback(
    (keepProvince = true) => {
      const resetFilters = {
        province: keepProvince ? (autoDetectEnabled ? filters.province : filters.province) : '',
        city: '',
        category: '',
        subCategory: '',
      };
      setFilters(resetFilters);
      onFilterChange(resetFilters);
    },
    [filters.province, autoDetectEnabled, onFilterChange]
  );

  useEffect(() => {
    fetchProvinces();
  }, []);

  return {
    filters,
    setFilters,
    categories,
    subCategories,
    provinces,
    cities,
    autoDetectEnabled,
    handleProvinceChange,
    handleCityChange,
    handleCategoryChange,
    handleSubCategoryChange,
    handleTagRemove,
    resetFilters,
    fetchCities,
  };
};

export default useMapFilters;