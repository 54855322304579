import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../config/supabaseClient';
import DashboardNav from '../components/DashboardNav';
import DashboardSidebar from '../components/DashboardSidebar';
import DashboardOverview from './DashboardOverview';
import DashboardPlaces from './DashboardPlaces';
import DashboardProfile from './DashboardProfile';
import DashboardDeals from './DashboardDeals';
import ReactApexChart from 'react-apexcharts';

function Dashboard() {
  const { user } = useAuth();
  const [activePage, setActivePage] = useState(() => {
    return localStorage.getItem('dashboardActivePage') || 'overview';
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    localStorage.setItem('dashboardActivePage', activePage);
    fetchMessages();
  }, [activePage]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSetActivePage = (page) => {
    setActivePage(page);
    localStorage.setItem('dashboardActivePage', page);
    // Close sidebar on mobile after selecting a page
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(false);
    }
  };

  const fetchMessages = async () => {
    const { data, error } = await supabase
      .from('messages')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(5);
    
    if (error) console.log('error', error);
    else setMessages(data);
  };

  const renderActivePage = () => {
    switch (activePage) {
      case 'overview':
        return <DashboardOverview user={user} />;
      case 'places':
        return <DashboardPlaces user={user} />;
      case 'profile':
        return <DashboardProfile user={user} />;
      case 'deals':
        return <DashboardDeals user={user} />;
      default:
        return <DashboardOverview user={user} />;
    }
  };

  const chartOptions = {
    chart: {
      type: 'line',
      height: 200,
      fontFamily: 'var(--font-family)',
    },
    series: [{
      name: 'Users',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    }],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
    },
    colors: ['var(--primary-color)'],
    stroke: {
      curve: 'smooth',
    },
  };

  const employeesData = [
    { id: 1, name: 'Floyd Miles', email: 'floydmiles@example.com', department: 'Design', joinDate: 'Jun. 24, 2023', status: 'Active' },
    { id: 2, name: 'Savannah Nguyen', email: 'savannahng@example.com', department: 'Research', joinDate: 'Feb. 23, 2023', status: 'Inactive' },
    { id: 3, name: 'Cameron Williamson', email: 'cameron@example.com', department: 'Development', joinDate: 'Oct. 23, 2023', status: 'Onboarding' },
  ];

  const upcomingSchedule = [
    { id: 1, title: 'Freshly cooked daily Prepared Meals', description: 'From meatloaf, mashed potatoes to grilled chicken with rice.', time: '15% off prepared meals.', priority: 'Ends Soon' },
    { id: 2, title: 'Compensation Review', description: 'Review and update salary structures', time: 'Present QR code for discount.', priority: 'Started' },
    { id: 3, title: 'Administrative Tasks', description: 'Handle paperwork and documentation', time: '25% Icecream', priority: '10 days left' },
  ];

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <DashboardNav user={user} />
      <div className="flex flex-1 overflow-hidden">
        <DashboardSidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          setActivePage={handleSetActivePage}
          activePage={activePage}
        />
        <main className={`flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 transition-all duration-300 ${isSidebarOpen ? 'lg:ml-64' : ''}`}>
          <button
            onClick={toggleSidebar}
            className="fixed top-16 left-4 z-20 rounded-md text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
          >
            <div className="p-2.5">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </div>
          </button>
          <div className="flex justify-center">
            <div className="w-full max-w-[2280px] px-4 py-8">
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
                <div className="lg:col-span-8 space-y-4">
                  <div className="bg-white rounded-xl p-4 shadow">
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
                      <div className="lg:col-span-5 h-full">
                        <div className="flex flex-row h-full space-x-4">
                          <div className="flex-1 p-4 bg-white rounded-lg shadow flex flex-col">
                            <div className="rounded-lg p-2.5 bg-red-50 w-max mb-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <div className="flex-grow flex flex-col justify-center">
                              <h5 className="text-xl font-semibold text-gray-900 leading-8">1012</h5>
                              <h6 className="text-sm font-normal text-gray-500" style={{ fontSize: '14px' }}>Favourites</h6>
                            </div>
                          </div>
                          <div className="flex-1 p-4 bg-white rounded-lg shadow flex flex-col">
                            <div className="rounded-lg p-2.5 bg-blue-50 w-max mb-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <div className="flex-grow flex flex-col justify-center">
                              <h5 className="text-xl font-semibold text-gray-900 leading-8">3854</h5>
                              <h6 className="text-sm font-normal text-gray-500" style={{ fontSize: '14px' }}>Profile Visits</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-7">
                        <div className="flex items-center justify-between pb-4">
                          <h5 className="text-base font-semibold text-gray-900">
                            User Interactions
                          </h5>
                        </div>
                        <ReactApexChart options={chartOptions} series={chartOptions.series} type="line" height={200} />
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-xl p-4 shadow overflow-x-auto">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4">
                      <h5 className="text-base font-semibold text-gray-900 mb-2 sm:mb-0">
                        Places
                      </h5>
                    </div>
                    <div className="overflow-x-auto">
                      <table className="w-full min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              <input type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Address
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              City
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Province
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Edit
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {employeesData.map((employee) => (
                            <tr key={employee.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <input type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-10 w-10">
                                    <img className="h-10 w-10 rounded-full" src={`https://ui-avatars.com/api/?name=${employee.name}&background=random`} alt="" />
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">{employee.name}</div>
                                    <div className="text-sm text-gray-500">{employee.email}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{employee.department}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{employee.joinDate}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                  employee.status === 'Active' ? 'bg-green-100 text-green-800' :
                                  employee.status === 'Inactive' ? 'bg-red-100 text-red-800' :
                                  'bg-yellow-100 text-yellow-800'
                                }`}>
                                  {employee.status}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <a href="#" className="text-indigo-600 hover:text-indigo-900">Edit</a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4 space-y-4">
                  <div className="bg-white rounded-xl p-4 shadow">
                    <h5 className="text-base font-semibold text-gray-900 mb-4">
                      Active Deals
                    </h5>
                    <div className="space-y-4">
                      {upcomingSchedule.map((item) => (
                        <div key={item.id} className="border-l-4 border-indigo-500 pl-4">
                          <div className="flex justify-between items-center mb-1">
                            <h6 className="text-sm font-semibold text-gray-900">{item.title}</h6>
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              item.priority === 'Critical' ? 'bg-red-100 text-red-800' :
                              item.priority === 'Urgent' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-green-100 text-green-800'
                            }`}>
                              {item.priority}
                            </span>
                          </div>
                          <p className="text-xs text-gray-500 mb-1">{item.description}</p>
                          <p className="text-xs text-gray-400">{item.time}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="bg-white rounded-xl p-4 shadow h-96">
                    <h5 className="text-base font-semibold text-gray-900 mb-4">
                      Recent Messages
                    </h5>
                    <div className="space-y-4 overflow-y-auto h-80">
                      {messages.map((message) => (
                        <div key={message.id} className="border-b pb-2">
                          <p className="text-sm text-gray-600">{message.content}</p>
                          <p className="text-xs text-gray-400">{new Date(message.created_at).toLocaleString()}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;