import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';

const UserTypeContext = createContext();

export const USER_TYPES = {
  NOT_SIGNED_IN: 'NOT_SIGNED_IN',
  FREE: 'FREE',
};

export const PLACE_TYPES = {
  FREE: 'FREE',
  PROFESSIONAL: 'PROFESSIONAL',
};

export const UserTypeProvider = ({ children }) => {
  const { user } = useAuth();
  const [userType, setUserType] = useState(USER_TYPES.NOT_SIGNED_IN);

  useEffect(() => {
    if (user) {
      setUserType(USER_TYPES.FREE);
    } else {
      setUserType(USER_TYPES.NOT_SIGNED_IN);
    }
  }, [user]);

  const canAccessFeature = (feature, placeType = PLACE_TYPES.FREE) => {
    switch (feature) {
      case 'favorite':
        return userType !== USER_TYPES.NOT_SIGNED_IN;
      case 'professional_content':
        return placeType === PLACE_TYPES.PROFESSIONAL;
      // Add more features as needed
      default:
        return false;
    }
  };

  return (
    <UserTypeContext.Provider value={{ userType, canAccessFeature }}>
      {children}
    </UserTypeContext.Provider>
  );
};

export const useUserType = () => useContext(UserTypeContext);