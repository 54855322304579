// LocationContext.js
import React, { createContext, useState, useContext } from 'react';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({
    country_code: '', // Updated from countryAbbreviation
    countryName: '', // Added to store the full country name
    province: '',
  });

  const updateLocation = (newLocation) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      ...newLocation,
    }));
  };

  const resetLocation = () => {
    setLocation({
      country_code: '',
      countryName: '',
      province: '',
    });
  };

  return (
    <LocationContext.Provider value={{ location, updateLocation, resetLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
