export const optimizePerformance = () => {
  let ticking = false;

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        // Perform any scroll-related operations here
        // For example, you could update some UI element based on scroll position:
        // updateScrollIndicator(window.scrollY);
        ticking = false;
      });

      ticking = true;
    }
  };

  window.addEventListener('scroll', onScroll, { passive: true });

  // Optimize setTimeout
  const originalSetTimeout = window.setTimeout;
  window.setTimeout = (handler, timeout, ...args) => {
    if (typeof handler === 'function') {
      const wrappedHandler = () => {
        performance.mark('setTimeout-start');
        try {
          handler();
        } finally {
          performance.mark('setTimeout-end');
          const measure = performance.measure('setTimeout-duration', 'setTimeout-start', 'setTimeout-end');
          if (measure.duration > 100) {  // 100ms threshold
            console.warn(`Long-running setTimeout detected: ${measure.duration.toFixed(2)}ms`);
          }
        }
      };
      return originalSetTimeout(wrappedHandler, timeout, ...args);
    }
    return originalSetTimeout(handler, timeout, ...args);
  };

  // Optimize message event handlers
  window.addEventListener('message', (event) => {
    performance.mark('message-start');
    // Your existing message event handling code here
    performance.mark('message-end');
    performance.measure('message-duration', 'message-start', 'message-end');
  });
};