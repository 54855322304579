// Function to set the last selected province
export const setLastSelectedProvince = (province) => {
  localStorage.setItem('preferredProvince', province);
};

// Function to get the province for the Map URL
export const getProvinceForMapUrl = () => {
  return encodeURIComponent(localStorage.getItem('preferredProvince') || 'Ontario');
};

// Function to get the current preferred province
export const getPreferredProvince = () => {
  return localStorage.getItem('preferredProvince') || 'Ontario';
};