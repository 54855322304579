import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import Place from './Place';
import TagBadgeList from './TagBadgeList';

const ListView = memo(({
  filteredPlaces,
  totalPlaces,
  handlePlaceHover,
  handlePlaceClick,
  loading,
  showNoResults,
  loadMorePlaces,
  loadingMore,
  filters,
  onTagRemove,
  favorites,
  addFavorite,
  removeFavorite,
  user,
  onShare,
  hasMore,
  sortOption
}) => {
  const [sortedPlaces, setSortedPlaces] = useState(filteredPlaces);
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    setIsSorting(true);
    const timer = setTimeout(() => {
      setSortedPlaces(filteredPlaces);
      setIsSorting(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [filteredPlaces, sortOption]);

  const observer = useRef();
  const lastPlaceElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMorePlaces();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore, loadMorePlaces]);

  const renderPlacesList = () => (
    <div className="places-grid p-4">
      {sortedPlaces.map((place, index) => (
        <div key={place.id} ref={index === sortedPlaces.length - 1 ? lastPlaceElementRef : null}>
          <Place 
            place={place}
            onPlaceHover={handlePlaceHover}
            onPlaceClick={handlePlaceClick}
            onPhotoClick={() => {
              handlePlaceClick(place);
            }}
            isFavorite={favorites.some(fav => fav.id === place.id)}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
            isSignedIn={!!user}
            onShare={onShare}
          />
        </div>
      ))}
    </div>
  );

  const renderLoadingState = () => (
    <div className="p-4">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderNoResults = () => (
    <div className="p-4 text-center">
      <p className="text-xl font-semibold">No alternative grocers found.</p>
      <p className="mt-2">Try adjusting your filters or exploring a different area.</p>
    </div>
  );

  return (
    <div className="h-full overflow-y-auto bg-white">
      <div className="p-4">
        <TagBadgeList filters={filters} onTagRemove={onTagRemove} />
      </div>
      {loading && sortedPlaces.length === 0 ? (
        <div className="p-4 text-center">
          <p>Loading places...</p>
          {renderLoadingState()}
        </div>
      ) : sortedPlaces.length > 0 ? (
        <div style={{ opacity: isSorting ? 0.5 : 1, transition: 'opacity 0.3s' }}>
          {renderPlacesList()}
        </div>
      ) : showNoResults ? (
        renderNoResults()
      ) : null}
      {loadingMore && (
        <div className="p-4 text-center">
          <p>Loading more places...</p>
          {renderLoadingState()}
        </div>
      )}
    </div>
  );
});

export default ListView;