// src/errorSuppress.js

export const suppressResizeObserverErrors = () => {
    const consoleError = console.error;
    console.error = (...args) => {
      if (
        args.length > 0 &&
        typeof args[0] === 'string' &&
        args[0].includes('ResizeObserver loop')
      ) {
        // Ignore ResizeObserver loop errors
        return;
      }
      consoleError(...args);
    };
  };