import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Layout({ children, hideHeader, hideFooter }) {
  return (
    <div className="flex flex-col min-h-screen">
      {!hideHeader && <Header />}
      <main className="flex-grow py-12 sm:py-16 md:py-20"> {/* Added padding classes here */}
        <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ maxWidth: '97rem' }}>
          {children}
        </div>
      </main>
      {!hideFooter && <Footer />}
    </div>
  );
}

export default Layout;