import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useLocationContext } from '../context/LocationContext';
import { useFavorites } from '../context/FavoritesContext';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import SecondaryNav from '../components/SecondaryNav';
import BottomNavigation from '../components/BottomNavigation';
import MapView from '../components/MapView';
import ListView from '../components/ListView';
import CountryDetector from '../components/CountryDetector';
import Share from '../components/Share';
import Announcement from '../components/Announcement';
import useMapInitialization from '../hooks/useMapInitialization';
import usePlaces from '../hooks/usePlaces';
import useMapFilters from '../hooks/useMapFilters';
import useResizeObserver from '../hooks/useResizeObserver';
import '../placeGrid.css';
import '../mapStyles.css';

const MapPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lng] = useState(-79.3832);
  const [lat] = useState(43.6532);
  const [zoom] = useState(9);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeView, setActiveView] = useState(isMobile ? 'listView' : 'mapView');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [shouldUpdateMap, setShouldUpdateMap] = useState(false);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [sharePlace, setSharePlace] = useState(null);

  const { location: detectedLocation } = useLocationContext();
  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const { user } = useAuth();

  const { mapContainer, map, initializeMap, handleResize: mapHandleResize } = useMapInitialization(lng, lat, zoom);
  
  const initialFilters = useMemo(() => {
    const provinceParam = searchParams.get('province');
    const detectedProvince = detectedLocation.province;
    let province = provinceParam || detectedProvince || 'Ontario';

    if (province.toLowerCase() !== 'ontario' && !provinceParam && !detectedProvince) {
      province = 'Ontario';
    }

    return {
      province,
      city: searchParams.get('city') || '',
      category: searchParams.get('category') || '',
      subCategory: searchParams.get('subCategory') || '',
    };
  }, [detectedLocation.province, searchParams]);

  const {
    places,
    loading,
    loadingMore,
    totalPlaces,
    sortOption,
    handleSort,
    loadMorePlaces,
    updateFilters,
    categories,
    subCategories,
    filters,
  } = usePlaces(initialFilters);

  const filteredCategories = useMemo(() => {
    const categorySet = new Set(places.map(place => place.category));
    return categories.filter(category => categorySet.has(category.name));
  }, [places, categories]);

  const filteredSubCategories = useMemo(() => {
    const subCategorySet = new Set(places.map(place => place.sub_category));
    return subCategories.filter(subCategory => subCategorySet.has(subCategory.name));
  }, [places, subCategories]);

  const {
    provinces,
    cities,
    handleProvinceChange,
    handleCityChange,
    handleCategoryChange,
    handleSubCategoryChange,
    handleTagRemove,
    resetFilters,
    fetchCities,
  } = useMapFilters(initialFilters, updateFilters, filteredCategories, filteredSubCategories);

  const handleTagRemoveAndUpdateFilters = useCallback((key) => {
    handleTagRemove(key);
    updateFilters({ ...filters, [key]: '' });
  }, [handleTagRemove, updateFilters, filters]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    if (filters.province) newSearchParams.set('province', filters.province);
    if (filters.city) newSearchParams.set('city', filters.city);
    if (filters.category) newSearchParams.set('category', filters.category);
    if (filters.subCategory) newSearchParams.set('subCategory', filters.subCategory);
    setSearchParams(newSearchParams);
  }, [filters, setSearchParams]);

  useEffect(() => {
    if (filters.province) {
      fetchCities(filters.province);
    }
  }, [filters.province, fetchCities]);

  const toggleView = useCallback((newView) => {
    setActiveView(newView);
    if (newView === 'mapView') {
      setTimeout(() => {
        if (map.current) {
          map.current.resize();
        }
      }, 300);
    }
    setIsFilterDrawerOpen(false);
  }, [map]);

  const toggleFilterDrawer = useCallback(() => {
    setIsFilterDrawerOpen((prev) => !prev);
  }, []);

  const createPopupContent = useCallback((place) => {
    return `
      <div class="custom-popup">
        <img src="${place.photo || 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp'}" alt="${place.name}" class="custom-popup-image" />
        <button class="custom-popup-close">
          <i class="fas fa-times"></i>
        </button>
        <div class="custom-popup-content">
          <h3>${place.name}</h3>
          <p>${place.address}</p>
          <div class="custom-popup-actions">
            <a href="${place.google_url || `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place.name + ' ' + place.address)}`}" target="_blank" rel="noopener noreferrer" class="bg-white bg-opacity-70 rounded-full w-8 h-8 flex items-center justify-center">
              <i class="fas fa-directions text-gray-600"></i>
            </a>
            <button class="share-place bg-white bg-opacity-70 rounded-full w-8 h-8 flex items-center justify-center" data-place-id="${place.id}">
              <i class="fas fa-share text-gray-600"></i>
            </button>
          </div>
        </div>
      </div>
    `;
  }, []);

  const handleShare = useCallback((place) => {
    console.log('handleShare called with place:', place);
    setSharePlace(place);
    setShowShareModal(true);
  }, []);

  const handlePlaceClick = useCallback((place) => {
    if (map.current && place.lng && place.lat) {
      if (currentPopup) {
        currentPopup.remove();
      }

      const popupContent = createPopupContent(place);
      const popup = new maplibregl.Popup({
        closeButton: true,
        className: 'custom-popup',
      })
        .setLngLat([place.lng, place.lat])
        .setHTML(popupContent)
        .addTo(map.current);

      popup.on('close', () => setCurrentPopup(null));

      const popupElement = popup.getElement();
      popupElement.querySelector('.custom-popup-close').addEventListener('click', () => popup.remove());
      popupElement.querySelector('.share-place').addEventListener('click', () => handleShare(place));

      setCurrentPopup(popup);

      const currentZoom = map.current.getZoom();
      const targetZoom = Math.max(currentZoom, 15);

      map.current.flyTo({
        center: [place.lng, place.lat],
        zoom: targetZoom,
        duration: 1000,
        essential: true
      });

      if (activeView === 'listView' && isMobile) {
        toggleView('mapView');
      }
    }
  }, [map, createPopupContent, currentPopup, activeView, isMobile, toggleView, handleShare]);

  const handleResize = useCallback(() => {
    const newIsMobile = window.innerWidth < 768;
    if (newIsMobile !== isMobile) {
      setIsMobile(newIsMobile);
      setActiveView(newIsMobile ? 'listView' : 'mapView');
    }
    mapHandleResize();
  }, [isMobile, mapHandleResize]);

  const { observe, unobserve } = useResizeObserver(handleResize);

  useEffect(() => {
    if (activeView === 'mapView') {
      initializeMap();
    }
    const currentMapContainer = mapContainer.current;
    if (currentMapContainer) {
      observe(currentMapContainer);
    }

    return () => {
      if (currentMapContainer) {
        unobserve(currentMapContainer);
      }
    };
  }, [initializeMap, observe, unobserve, activeView, mapContainer]);

  useEffect(() => {
    if (map.current) {
      map.current.on('load', () => {
        setMapLoaded(true);
      });
    }
  }, [map]);

  useEffect(() => {
    if (mapLoaded && places.length > 0) {
      setShouldUpdateMap(true);
    }
  }, [mapLoaded, places]);

  const showNoResults = useMemo(() => places.length === 0 && !loading, [places, loading]);

  const currentProvince = filters.province || 'All Provinces';

  return (
    <>
      <Announcement />
      <div className="flex flex-col h-screen font-custom">
        <CountryDetector />
        <Header isMobile={isMobile} />
        <div className={`relative ${isMobile ? 'z-[9999]' : 'z-[100]'}`}>
          <SecondaryNav
            onFilterChange={updateFilters}
            isMobile={isMobile}
            isMenuOpen={isFilterDrawerOpen}
            setIsMenuOpen={setIsFilterDrawerOpen}
            filters={filters}
            categories={filteredCategories}
            subCategories={filteredSubCategories}
            onCategoryChange={handleCategoryChange}
            onSubCategoryChange={handleSubCategoryChange}
            resetFilters={resetFilters}
            detectedProvince={filters.province}
            onProvinceChange={handleProvinceChange}
            provinces={provinces}
            cities={cities}
            onCityChange={handleCityChange}
            onTagRemove={handleTagRemoveAndUpdateFilters}
          />
        </div>
        <div className="flex flex-1 overflow-hidden">
          {isMobile ? (
            <div className="relative w-full h-full">
              <div 
                className={`absolute inset-0 transition-opacity duration-300 ${
                  activeView === 'mapView' ? 'opacity-100 z-10' : 'opacity-0 z-0 pointer-events-none'
                }`} 
              >
                <MapView
                  mapContainer={mapContainer}
                  map={map}
                  filteredPlaces={places}
                  handlePlaceClick={handlePlaceClick}
                  createPopupContent={createPopupContent}
                  favorites={favorites}
                  mapLoaded={mapLoaded}
                  shouldUpdateMap={shouldUpdateMap}
                  setShouldUpdateMap={setShouldUpdateMap}
                />
              </div>
              <div 
                className={`absolute inset-0 bg-white transition-opacity duration-300 ${
                  activeView === 'listView' ? 'opacity-100 z-20' : 'opacity-0 z-0 pointer-events-none'
                }`}
              >
                <div className="h-full flex flex-col">
                  <div className="p-4 flex-shrink-0">
                    <div className="flex justify-between items-center">
                      <div>
                        <h2 className="text-xl font-bold">Current Province: {currentProvince}</h2>
                        <p className="text-gray-600">{places.length} of {totalPlaces}</p>
                      </div>
                      <select 
                        value={sortOption} 
                        onChange={(e) => handleSort(e.target.value)}
                        className="p-2 border rounded text-sm"
                        style={{ width: 'auto' }}
                        disabled={loading}
                      >
                        <option value="recent">Recent</option>
                        <option value="a-z">A-Z</option>
                        <option value="z-a">Z-A</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex-grow overflow-y-auto p-4 pb-24">
                    <ListView
                      filteredPlaces={places}
                      totalPlaces={totalPlaces}
                      onTagRemove={handleTagRemoveAndUpdateFilters}
                      handlePlaceClick={handlePlaceClick}
                      loading={loading}
                      showNoResults={showNoResults}
                      loadMorePlaces={loadMorePlaces}
                      loadingMore={loadingMore}
                      filters={filters}
                      updateFilters={updateFilters}
                      favorites={favorites}
                      addFavorite={addFavorite}
                      removeFavorite={removeFavorite}
                      user={user}
                      onShare={handleShare}
                      hasMore={places.length < totalPlaces}
                    />
                  </div>
                </div>
              </div>
              <BottomNavigation
                activeView={activeView}
                toggleView={toggleView}
                toggleFilterDrawer={toggleFilterDrawer}
                isFilterDrawerOpen={isFilterDrawerOpen}
              />
            </div>
          ) : (
            <>
              <div className="w-1/3 h-full flex flex-col bg-white">
                <div className="p-4 flex-shrink-0">
                  <div className="flex justify-between items-center">
                    <div>
                      <h2 className="text-xl font-bold">Current Province: {currentProvince}</h2>
                      <p className="text-gray-600">{places.length} of {totalPlaces}</p>
                    </div>
                    <select 
                      value={sortOption} 
                      onChange={(e) => handleSort(e.target.value)}
                      className="p-2 border rounded text-sm"
                      style={{ width: 'auto' }}
                      disabled={loading}
                    >
                      <option value="recent">Recent</option>
                      <option value="a-z">A-Z</option>
                      <option value="z-a">Z-A</option>
                    </select>
                  </div>
                </div>
                <div className="flex-grow overflow-y-auto p-4">
                  <ListView
                    filteredPlaces={places}
                    totalPlaces={totalPlaces}
                    onTagRemove={handleTagRemoveAndUpdateFilters}
                    handlePlaceClick={handlePlaceClick}
                    loading={loading}
                    showNoResults={showNoResults}
                    loadMorePlaces={loadMorePlaces}
                    loadingMore={loadingMore}
                    filters={filters}
                    updateFilters={updateFilters}
                    favorites={favorites}
                    addFavorite={addFavorite}
                    removeFavorite={removeFavorite}
                    user={user}
                    onShare={handleShare}
                    hasMore={places.length < totalPlaces}
                  />
                </div>
              </div>
              <div className="w-2/3 h-full relative">
                <MapView
                  mapContainer={mapContainer}
                  map={map}
                  filteredPlaces={places}
                  handlePlaceClick={handlePlaceClick}
                  createPopupContent={createPopupContent}
                  favorites={favorites}
                  mapLoaded={mapLoaded}
                  shouldUpdateMap={shouldUpdateMap}
                  setShouldUpdateMap={setShouldUpdateMap}
                />
              </div>
            </>
          )}
        </div>
        {showShareModal && (
          <Share 
            place={sharePlace} 
            onClose={() => {
              console.log('Closing share modal');
              setShowShareModal(false);
              setSharePlace(null);
            }} 
          />
        )}
      </div>
    </>
  );
};

export default MapPage;
