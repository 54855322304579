import React, { useEffect, useCallback, useRef } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const MapView = ({
  mapContainer,
  map,
  filteredPlaces,
  handlePlaceClick,
  favorites,
  mapLoaded,
  shouldUpdateMap,
  setShouldUpdateMap
}) => {
  const isUpdatingRef = useRef(false);
  const clickListenerRef = useRef(null);

  const handleMapClick = useCallback((e) => {
    if (!map.current) return;

    const features = map.current.queryRenderedFeatures(e.point, { layers: ['places'] });
    if (features.length > 0) {
      const clickedPlace = filteredPlaces.find(p => p.id === features[0].properties.id);
      if (clickedPlace) {
        handlePlaceClick(clickedPlace);
      }
    }
  }, [map, filteredPlaces, handlePlaceClick]);

  const handleZoomIn = () => {
    if (map.current) {
      map.current.zoomIn();
    }
  };

  const handleZoomOut = () => {
    if (map.current) {
      map.current.zoomOut();
    }
  };

  const updateMap = useCallback(() => {
    if (!map.current) return;

    console.log('Updating map');
    console.log('Total filtered places:', filteredPlaces.length);

    const placesWithCoordinates = filteredPlaces.filter(place => place.lng && place.lat);
    const placesWithoutCoordinates = filteredPlaces.filter(place => !place.lng || !place.lat);

    console.log('Places with coordinates:', placesWithCoordinates.length);
    console.log('Places without coordinates:', placesWithoutCoordinates.length);

    if (placesWithCoordinates.length > 0) {
      console.log('Sample place with coordinates:', placesWithCoordinates[0]);
    }

    if (placesWithoutCoordinates.length > 0) {
      console.log('Sample place without coordinates:', placesWithoutCoordinates[0]);
    }

    const geojson = {
      type: 'FeatureCollection',
      features: placesWithCoordinates.map(place => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [place.lng, place.lat]
        },
        properties: {
          id: place.id,
          name: place.name,
          address: place.address,
          isFavorite: favorites.some(fav => fav.id === place.id),
        }
      }))
    };

    if (map.current.getSource('places')) {
      map.current.getSource('places').setData(geojson);
    } else {
      map.current.addSource('places', { type: 'geojson', data: geojson });
      map.current.addLayer({
        id: 'places',
        type: 'circle',
        source: 'places',
        paint: {
          'circle-radius': 10,
          'circle-color': [
            'case',
            ['get', 'isFavorite'],
            '#FF0000', // Red color for favorites
            '#FFCC4A'  // Default color
          ],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#333',
        },
      });
    }

    // Remove existing click listener
    if (clickListenerRef.current) {
      map.current.off('click', clickListenerRef.current);
    }

    // Add new click listener
    clickListenerRef.current = handleMapClick;
    map.current.on('click', clickListenerRef.current);

    map.current.on('mouseenter', 'places', () => {
      if (map.current) {
        map.current.getCanvas().style.cursor = 'pointer';
      }
    });

    map.current.on('mouseleave', 'places', () => {
      if (map.current) {
        map.current.getCanvas().style.cursor = '';
      }
    });

    if (placesWithCoordinates.length > 0 && shouldUpdateMap) {
      const bounds = new maplibregl.LngLatBounds();
      placesWithCoordinates.forEach(place => {
        bounds.extend([place.lng, place.lat]);
      });
      
      if (!bounds.isEmpty()) {
        isUpdatingRef.current = true;
        map.current.fitBounds(bounds, { padding: 50, maxZoom: 15 });
        setTimeout(() => {
          isUpdatingRef.current = false;
        }, 1000);
      } else {
        console.warn('No valid coordinates to fit bounds');
      }
    } else if (placesWithCoordinates.length === 0) {
      console.warn('No places with coordinates to display on the map');
    }

    if (placesWithoutCoordinates.length > 0) {
      console.log(`${placesWithoutCoordinates.length} places without coordinates are not shown on the map`);
    }
  }, [filteredPlaces, favorites, handleMapClick, map, shouldUpdateMap]);

  useEffect(() => {
    if (mapLoaded && shouldUpdateMap) {
      updateMap();
      setShouldUpdateMap(false);
    }
  }, [mapLoaded, shouldUpdateMap, setShouldUpdateMap, updateMap, map]);

  useEffect(() => {
    if (map.current && mapLoaded) {
      updateMap();
    }
  }, [filteredPlaces, mapLoaded, updateMap, map]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const currentMap = map.current;
    return () => {
      // Cleanup function to remove the click listener when the component unmounts
      if (currentMap && clickListenerRef.current) {
        currentMap.off('click', clickListenerRef.current);
      }
    };
     // eslint-disable-next-line 
  }, []);

  return (
    <div className="map-container-wrapper">
      <div ref={mapContainer} className="map-container" />
      <div className="map-controls">
        <button onClick={handleZoomIn} className="zoom-button zoom-in">+</button>
        <button onClick={handleZoomOut} className="zoom-button zoom-out">-</button>
      </div>
    </div>
  );
};

export default MapView;