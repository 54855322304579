import { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';
import { useAuth } from '../context/AuthContext';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';

export const useRecommendForm = (userLocation) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    unit: '',
    city: '',
    postal_code: '',
    province: '',
    prov_id: null,
    country: '',
    country_id: null,
    description: '',
    website: '',
    phone: '',
    category: '',
    cat_id: null,
    sub_category: '',
    sub_cat_id: null,
    email: '',
    facebook: '',
    instagram: '',
    google_maps_url: '',
    photo: null
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [nameError, setNameError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');
  const [bannedStores, setBannedStores] = useState([]);
  const [isDuplicateBusiness, setIsDuplicateBusiness] = useState(false);
  const [duplicateBusiness, setDuplicateBusiness] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isGoogleMapsApiWorking, setIsGoogleMapsApiWorking] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchBannedStores(),
          fetchCategories(),
          fetchSubcategories(),
          fetchProvinces(),
          fetchCountries(),
          checkGoogleMapsApiKey()
        ]);
      } catch (error) {
        toast.error('Failed to load some data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const checkGoogleMapsApiKey = async () => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    
    if (!apiKey) {
      setIsGoogleMapsApiWorking(false);
      toast.error('Google Maps API key is not configured');
      return;
    }

    const testAddress = 'Toronto, ON, Canada';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(testAddress)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (data.status === 'OK') {
        setIsGoogleMapsApiWorking(true);
      } else {
        setIsGoogleMapsApiWorking(false);
        toast.error('There was an issue with the Google Maps API. Please try again later.');
      }
    } catch (error) {
      setIsGoogleMapsApiWorking(false);
      toast.error('Unable to verify Google Maps API key. Please try again later.');
    }
  };

  const fetchBannedStores = async () => {
    try {
      const { data, error } = await supabase
        .from('ban')
        .select('store_name');
      if (error) throw error;
      setBannedStores(data.map(item => item.store_name.toLowerCase()));
    } catch (error) {
      toast.error('Failed to fetch banned stores');
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('categories')
        .select('*');
      if (error) throw error;
      setCategories(data);
    } catch (error) {
      toast.error('Failed to fetch categories');
    }
  };

  const fetchSubcategories = async () => {
    try {
      const { data, error } = await supabase
        .from('subcategories')
        .select('*');
      if (error) throw error;
      setSubcategories(data);
    } catch (error) {
      toast.error('Failed to fetch subcategories');
    }
  };

  const fetchProvinces = async () => {
    try {
      const { data, error } = await supabase
        .from('provinces')
        .select('*');
      if (error) throw error;
      setProvinces(data);
    } catch (error) {
      toast.error('Failed to fetch provinces');
    }
  };

  const fetchCountries = async () => {
    try {
      const { data, error } = await supabase
        .from('countries')
        .select('*');
      if (error) throw error;
      setCountries(data);
    } catch (error) {
      toast.error('Failed to fetch countries');
    }
  };

  const handleChange = async (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else if (name === 'name') {
      setFormData({ ...formData, [name]: value });
      validateStoreName(value);
    } else if (name === 'category') {
      const selectedCategory = categories.find(cat => cat.id.toString() === value);
      if (selectedCategory) {
        setFormData({
          ...formData,
          category: selectedCategory.name,
          cat_id: selectedCategory.id,
          sub_category: '',
          sub_cat_id: null
        });
      }
    } else if (name === 'sub_category') {
      const selectedSubcategory = subcategories.find(subcat => subcat.id.toString() === value);
      if (selectedSubcategory) {
        setFormData({
          ...formData,
          sub_category: selectedSubcategory.name,
          sub_cat_id: selectedSubcategory.id
        });
      }
    } else if (name === 'country') {
      const selectedCountry = countries.find(country => country.id.toString() === value);
      if (selectedCountry) {
        setFormData({
          ...formData,
          country: selectedCountry.name,
          country_id: selectedCountry.id,
          province: '',
          prov_id: null
        });
      }
    } else if (name === 'province') {
      const selectedProvince = provinces.find(province => province.id.toString() === value);
      if (selectedProvince) {
        setFormData({
          ...formData,
          province: selectedProvince.name,
          prov_id: selectedProvince.id
        });
      }
    } else {
      let formattedValue = value;
      
      switch (name) {
        case 'website':
          formattedValue = formatWebsite(value);
          break;
        case 'facebook':
        case 'instagram':
          formattedValue = formatSocialMedia(name, value);
          break;
        case 'phone':
          formattedValue = formatPhoneNumber(value);
          break;
        case 'postal_code':
          formattedValue = formatPostalCode(value);
          break;
        case 'email':
          formattedValue = formatEmail(value);
          break;
        case 'google_maps_url':
          formattedValue = formatGoogleMapsUrl(value);
          break;
        default:
          formattedValue = value;
      }

      setFormData({ ...formData, [name]: formattedValue });
    }

    if (name === 'name' || name === 'address' || name === 'city') {
      const updatedFormData = { ...formData, [name]: value };
      if (updatedFormData.name && updatedFormData.address && updatedFormData.city) {
        const isDuplicate = await checkForDuplicates(updatedFormData.name, updatedFormData.address, updatedFormData.city);
        setIsDuplicateBusiness(isDuplicate);
        if (!isDuplicate) {
          setDuplicateError('');
          setDuplicateBusiness(null);
        }
      }
    }
  };

  const formatWebsite = (value) => {
    let formattedValue = value.trim();
    if (formattedValue && !formattedValue.startsWith('http://') && !formattedValue.startsWith('https://')) {
      formattedValue = `https://${formattedValue}`;
    }
    return formattedValue;
  };

  const formatSocialMedia = (platform, value) => {
    const cleanValue = value.trim().replace(/^@/, '');
    if (!cleanValue.startsWith('http://') && !cleanValue.startsWith('https://')) {
      return `https://www.${platform}.com/${cleanValue}`;
    }
    return value;
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '');
    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const formatPostalCode = (value) => {
    const cleanedValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    if (cleanedValue.length > 3) {
      return `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(3, 6)}`;
    }
    return cleanedValue;
  };

  const formatEmail = (value) => {
    return value.trim().toLowerCase();
  };

  const formatGoogleMapsUrl = (value) => {
    let formattedValue = value.trim();
    if (formattedValue && !formattedValue.startsWith('http://') && !formattedValue.startsWith('https://')) {
      formattedValue = `https://${formattedValue}`;
    }
    return formattedValue;
  };

  const handleWebsiteChange = (e) => {
    const { value } = e.target;
    let formattedValue = value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      formattedValue = `https://${value}`;
    }
    setFormData({ ...formData, website: formattedValue });
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      formattedValue = `https://www.${name}.com/${value.replace(/^@/, '')}`;
    }
    setFormData({ ...formData, [name]: formattedValue });
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    setFormData({ ...formData, phone: formattedValue });
  };

  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPostalCode(value);
    setFormData({ ...formData, postal_code: formattedValue });
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatEmail(value);
    setFormData({ ...formData, email: formattedValue });
  };

  const handleGoogleMapsUrlChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatGoogleMapsUrl(value);
    setFormData({ ...formData, google_maps_url: formattedValue });
  };

  const validateStoreName = (name) => {
    if (bannedStores.includes(name.toLowerCase())) {
      setNameError('This store name is not allowed. Please enter a different name.');
    } else {
      setNameError('');
    }
  };

  const checkForDuplicates = async (name, address, city) => {
    const { data: placesData, error: placesError } = await supabase
      .from('places')
      .select('*')
      .ilike('name', name)
      .ilike('address', address)
      .ilike('city', city);
  
    const { data: recommendationsData, error: recommendationsError } = await supabase
      .from('recommendations')
      .select('*')
      .ilike('name', name)
      .ilike('address', address)
      .ilike('city', city);
  
    if (placesError && recommendationsError) {
      setDuplicateError('An error occurred while checking for duplicate businesses. Please try again.');
      return false;
    }
  
    const combinedData = [...(placesData || []), ...(recommendationsData || [])];
  
    if (combinedData.length > 0) {
      const duplicate = combinedData[0];
      setDuplicateBusiness(duplicate);
      if (duplicate.claimed) {
        setDuplicateError('This business already exists in our database and has been claimed.');
      } else {
        setDuplicateError('This business already exists in our database but has not been claimed.');
      }
      return true;
    }
  
    setDuplicateBusiness(null);
    setDuplicateError('');
    return false;
  };

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-') + '-' + Date.now();
  };

  const getCoordinates = async (address, unit, city, province, postalCode, country) => {
    if (!isGoogleMapsApiWorking) {
      return { lat: null, lng: null, googleMapsUrl: null };
    }

    let fullAddress = `${address}, ${city}, ${province} ${postalCode}, ${country}`;
    if (unit) {
      fullAddress = `${unit} ${fullAddress}`;
    }
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    
    if (!apiKey) {
      return { lat: null, lng: null, googleMapsUrl: null };
    }

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const placeId = data.results[0].place_id;
        const googleMapsUrl = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
        return { 
          lat: lat,
          lng: lng,
          googleMapsUrl 
        };
      } else {
        return { lat: null, lng: null, googleMapsUrl: null };
      }
    } catch (error) {
      return { lat: null, lng: null, googleMapsUrl: null };
    }
  };

  const optimizeAndUploadImage = async (imageFile, slug) => {
    const options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 664,
      useWebWorker: true,
      fileType: 'image/webp',
    };

    try {
      let compressedFile = await imageCompression(imageFile, options);

      if (compressedFile.size > 51200) {
        const stricterOptions = {
          ...options,
          maxSizeMB: 0.049,
          maxWidthOrHeight: 600,
        };
        compressedFile = await imageCompression(compressedFile, stricterOptions);
      }

      const fileName = `${slug}.webp`;
      const filePath = `photos/uploaded/${fileName}`;
      
      const { error: uploadError } = await supabase.storage
        .from('photos')
        .upload(filePath, compressedFile, {
          contentType: 'image/webp'
        });

      if (uploadError) {
        throw uploadError;
      }

      const { data: urlData, error: urlError } = await supabase.storage
        .from('photos')
        .getPublicUrl(filePath);

      if (urlError) {
        throw urlError;
      }

      return urlData.publicUrl;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!user) {
      toast.error('You must be logged in to submit a recommendation.');
      return;
    }

    if (nameError || duplicateError) {
      toast.error('Please fix the errors before submitting.');
      return;
    }

    const slug = generateSlug(formData.name);

    let lat, lng, googleMapsUrl;
    try {
      ({ lat, lng, googleMapsUrl } = await getCoordinates(formData.address, formData.unit, formData.city, formData.province, formData.postal_code, formData.country));
    } catch (error) {
      toast.error('Unable to geocode the address. Please check the address and try again.');
      return;
    }

    let photoUrl = null;
    if (formData.photo) {
      try {
        photoUrl = await optimizeAndUploadImage(formData.photo, slug);
      } catch (error) {
        toast.error(`Error uploading photo: ${error.message}`);
        return;
      }
    }

    const recommendationData = {
      name: formData.name,
      address: formData.address,
      city: formData.city,
      province: formData.province,
      prov_id: formData.prov_id,
      country: formData.country,
      country_id: formData.country_id,
      category: formData.category,
      cat_id: formData.cat_id,
      user_id: user.id,
      lat: lat !== null ? Number(lat) : null,
      lng: lng !== null ? Number(lng) : null,
      slug: slug,
      unit: formData.unit || null,
      postal_code: formData.postal_code || null,
      description: formData.description || null,
      website: formData.website || null,
      phone: formData.phone || null,
      sub_category: formData.sub_category || null,
      sub_cat_id: formData.sub_cat_id || null,
      email: formData.email || null,
      facebook: formData.facebook || null,
      instagram: formData.instagram || null,
      google_maps_url: googleMapsUrl,
      photo: photoUrl || null,
      claimed: false,
      approved: false
    };

    // Remove any null values
    Object.keys(recommendationData).forEach(key => 
      recommendationData[key] === null && delete recommendationData[key]
    );

    try {
      const { data, error } = await supabase
        .from('recommendations')
        .insert(recommendationData)
        .select();
      
      if (error) {
        throw error;
      }

      if (data && data.length > 0) {
        toast.success('Thank you for your recommendation! It will be reviewed shortly.');
        resetForm();
      } else {
        throw new Error('No data returned after insertion');
      }
    } catch (error) {
      toast.error(`Error submitting recommendation: ${error.message}`);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '', address: '', unit: '', city: '', postal_code: '', 
      province: '', prov_id: null, country: '', country_id: null,
      description: '', website: '', phone: '', email: '', facebook: '', instagram: '',
      google_maps_url: '', category: '', cat_id: null, sub_category: '', sub_cat_id: null, photo: null
    });
    setNameError('');
    setDuplicateError('');
    setIsDuplicateBusiness(false);
    setDuplicateBusiness(null);
  };

  return {
    formData,
    setFormData,
    categories,
    subcategories,
    provinces,
    countries,
    nameError,
    duplicateError,
    handleChange,
    handleWebsiteChange,
    handleSocialMediaChange,
    handlePhoneChange,
    handlePostalCodeChange,
    handleEmailChange,
    handleGoogleMapsUrlChange,
    handleSubmit,
    resetForm,
    isDisabled: !!nameError || !!duplicateError || !user || isDuplicateBusiness || !isGoogleMapsApiWorking,
    duplicateBusiness,
    isLoading,
    isGoogleMapsApiWorking
  };
};