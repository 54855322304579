import React, { lazy, Suspense, useState } from 'react';
import HomeNav from '../components/HomeNav';
import Footer from '../components/Footer';
import { useAuth } from '../hooks/useAuth';
import { useRecommendForm } from '../hooks/useRecommendForm';

const RecommendForm = lazy(() => import('../components/RecommendForm'));
const ConfirmModal = lazy(() => import('../components/ConfirmModal'));
const DuplicateModal = lazy(() => import('../components/DuplicateModal'));
const OwnerVerificationModal = lazy(() => import('../components/OwnerVerificationModal'));

function Recommend() {
  const { user } = useAuth();
  const {
    formData,
    handleChange,
    handleSubmit,
    resetForm,
    categories,
    subcategories,
    nameError,
    isDisabled
  } = useRecommendForm(user);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showOwnerVerificationModal, setShowOwnerVerificationModal] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const onConfirmSubmit = () => {
    setShowConfirmModal(false);
    handleSubmit();
  };

  const onDuplicateConfirm = () => {
    setShowDuplicateModal(false);
    setShowOwnerVerificationModal(true);
  };

  const onVerifyOwner = () => {
    setShowOwnerVerificationModal(false);
    // Implement owner verification logic here
  };

  return (
    <div className="min-h-screen flex flex-col" style={{ backgroundColor: "#ffecd4" }}>
      <HomeNav />
      <main className="flex-grow">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Recommend a Business
              </h1>
              {user ? (
                <Suspense fallback={<div>Loading form...</div>}>
                  <RecommendForm
                    formData={formData}
                    handleChange={handleChange}
                    handleSubmit={onSubmit}
                    resetForm={resetForm}
                    categories={categories}
                    subcategories={subcategories}
                    nameError={nameError}
                    isDisabled={isDisabled}
                  />
                </Suspense>
              ) : (
                <div className="mt-5">
                  <p className="text-red-600">You must be logged in to submit a recommendation.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <div className="w-full bg-white">
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8">
          <Footer 
            customStyle={{ 
              width: '100%'
            }} 
            textAlignLeft={true}
          />
        </div>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <ConfirmModal 
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onConfirmSubmit}
        />
        <DuplicateModal 
          isOpen={showDuplicateModal}
          onClose={() => setShowDuplicateModal(false)}
          onConfirm={onDuplicateConfirm}
        />
        <OwnerVerificationModal 
          isOpen={showOwnerVerificationModal}
          onClose={() => setShowOwnerVerificationModal(false)}
          onVerify={onVerifyOwner}
        />
      </Suspense>
    </div>
  );
}

export default React.memo(Recommend);