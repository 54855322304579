import React, { createContext, useState, useContext, useCallback } from 'react';

const FavoritesContext = createContext();

export function useFavorites() {
  return useContext(FavoritesContext);
}

export function FavoritesProvider({ children }) {
  const [favorites, setFavorites] = useState([]);

  const addFavorite = useCallback((place) => {
    return new Promise((resolve) => {
      setFavorites((prevFavorites) => {
        if (!prevFavorites.some(fav => fav.id === place.id)) {
          resolve(true);
          return [...prevFavorites, place];
        }
        resolve(false);
        return prevFavorites;
      });
    });
  }, []);

  const removeFavorite = useCallback((placeId) => {
    return new Promise((resolve) => {
      setFavorites((prevFavorites) => {
        const newFavorites = prevFavorites.filter((place) => place.id !== placeId);
        resolve(prevFavorites.length !== newFavorites.length);
        return newFavorites;
      });
    });
  }, []);

  return (
    <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite }}>
      {children}
    </FavoritesContext.Provider>
  );
}