import React, { useState } from 'react';
import { supabase } from '../config/supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';

function SignUp() {
  // Temporary sign-up disabled message
  return (
    <div className="flex min-h-screen items-center justify-center py-12 px-6 lg:px-8" style={{ backgroundColor: '#FDCD5E' }}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <div className="text-center">
            <Link to="/" className="block mx-auto mb-6">
              <img 
                src="https://cdn.prod.website-files.com/65f70e175676e7203e7230f6/66a7929b9a2f56f7aa461b62_ag2-png-full-p-500.png"
                alt="Alternative Grocers Logo"
                className="h-10 w-auto mx-auto"
              />
            </Link>
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign-ups Currently Unavailable
            </h2>
          </div>
          <div className="mt-6 text-center">
            <p className="text-sm text-gray-500">
              We're sorry, but sign-ups are temporarily disabled. Please check back later.
            </p>
          </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{' '}
            <Link to="/signin" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );

  /* Original sign-up functionality (commented out)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
      });

      if (error) throw error;

      if (data.user) {
        toast.success('Account created successfully! Please check your email for confirmation.');
        navigate('/signin');
      } else {
        toast.success('Please check your email for the confirmation link.');
      }
    } catch (error) {
      if (error.message.includes('Email rate limit exceeded') || error.message.includes('User already registered')) {
        toast.error('An account with this email already exists. Please try signing in instead.');
      } else {
        toast.error('An error occurred during sign up. Please try again later.');
      }
      console.error('Sign up error:', error.message);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center py-12 px-6 lg:px-8" style={{ backgroundColor: '#FDCD5E' }}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <div className="text-center">
            <Link to="/" className="block mx-auto mb-6">
              <img 
                src="https://cdn.prod.website-files.com/65f70e175676e7203e7230f6/66a7929b9a2f56f7aa461b62_ag2-png-full-p-500.png"
                alt="Alternative Grocers Logo"
                className="h-10 w-auto mx-auto"
              />
            </Link>
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign up for an account
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="mt-6 space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  autoComplete="new-password"
                  className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="********"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign up
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{' '}
            <Link to="/signin" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
  */
}

export default SignUp;
