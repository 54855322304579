import React, { useMemo } from 'react';
import { usePublicRecommendForm } from '../hooks/usePublicRecommendForm';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function PublicRecommendForm() {
  const {
    formData,
    handleChange,
    handleDescriptionChange,
    handleWebsiteChange,
    handleSocialMediaChange,
    handlePhoneChange,
    handlePostalCodeChange,
    handleEmailChange,
    handleGoogleMapsUrlChange,
    handlePhotoChange,
    handleSubmit,
    resetForm,
    categories,
    subcategories,
    countries,
    provinces,
    nameError,
    descriptionError,
    isDisabled,
    isLoading
  } = usePublicRecommendForm();

  const sortedProvinces = useMemo(() => {
    return [...provinces].sort((a, b) => a.name.localeCompare(b.name));
  }, [provinces]);

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
  };

  const quillFormats = [
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'link'
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white border border-gray-200 rounded px-8 pt-6 pb-8 mb-4">
      <input type="hidden" name="lat" value={formData.lat || ''} />
      <input type="hidden" name="lng" value={formData.lng || ''} />

      {/* Business Name */}
      <div className="mb-4 sm:mb-0">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">Business Name *</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
        {nameError && <p className="mt-2 text-sm text-red-600">{nameError}</p>}
      </div>

      {/* Address, Unit, Postal Code */}
      <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-3">
        <div className="mb-4 sm:mb-0">
          <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-2">Address *</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="unit" className="block text-sm font-medium text-gray-700 mb-2">Unit</label>
          <input
            type="text"
            id="unit"
            name="unit"
            value={formData.unit}
            onChange={handleChange}
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700 mb-2">Postal/Zip Code</label>
          <input
            type="text"
            id="postal_code"
            name="postal_code"
            value={formData.postal_code}
            onChange={handlePostalCodeChange}
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>
      </div>

      {/* City, Country, Province */}
      <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-3">
        <div className="mb-4 sm:mb-0">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-2">City *</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-2">Country *</label>
          <div className="relative">
            <select
              id="country"
              name="country"
              value={formData.country || ''}
              onChange={handleChange}
              required
              disabled={isDisabled}
              className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500 appearance-none pr-8"
            >
              <option value="">Select a Country</option>
              {countries.map(country => (
                <option key={country.id} value={country.name}>{country.name}</option>
              ))}
            </select>
            <ChevronDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="province" className="block text-sm font-medium text-gray-700 mb-2">Province / State *</label>
          <div className="relative">
            <select
              id="province"
              name="province"
              value={formData.province || ''}
              onChange={handleChange}
              required
              disabled={isDisabled || !formData.country}
              className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500 appearance-none pr-8"
            >
              <option value="">
                {formData.country ? 'Choose a Province/State' : 'Choose a Country First'}
              </option>
              {sortedProvinces.map(province => (
                <option key={province.id} value={province.name}>{province.name}</option>
              ))}
            </select>
            <ChevronDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>

      {/* Description */}
      <div className="mb-4 sm:mb-0">
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-2">Description</label>
        <div className="mt-1" style={{ height: '200px' }}>
          <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={handleDescriptionChange}
            modules={quillModules}
            formats={quillFormats}
            style={{ height: '170px', borderRadius: '0.375rem' }}
            className="rounded-md"
          />
        </div>
        <p className="mt-4 text-sm text-gray-500 leading-6">
          {formData.description.length}/500 characters
        </p>
        {descriptionError && <p className="mt-2 text-sm text-red-600">{descriptionError}</p>}
      </div>

      {/* Website, Phone, Email */}
      <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-3">
        <div className="mb-4 sm:mb-0">
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-2">Website</label>
          <input
            type="url"
            id="website"
            name="website"
            value={formData.website}
            onChange={handleWebsiteChange}
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handlePhoneChange}
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleEmailChange}
            disabled={isDisabled}
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>
      </div>

      {/* Category and Subcategory */}
      <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2">
        <div className="mb-4 sm:mb-0">
          <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-2">Category *</label>
          <div className="relative">
            <select
              id="category"
              name="category"
              value={formData.cat_id || ''}
              onChange={handleChange}
              required
              disabled={isDisabled}
              className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500 appearance-none pr-8"
            >
              <option value="">Select a category</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
            <ChevronDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>

        {formData.cat_id && subcategories.some(sub => sub.cat_id === formData.cat_id) && (
          <div className="mb-4 sm:mb-0">
            <label htmlFor="sub_category" className="block text-sm font-medium text-gray-700 mb-2">Subcategory</label>
            <div className="relative">
              <select
                id="sub_category"
                name="sub_category"
                value={formData.sub_cat_id || ''}
                onChange={handleChange}
                disabled={isDisabled}
                className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500 appearance-none pr-8"
              >
                <option value="">Select a subcategory</option>
                {subcategories
                  .filter(sub => sub.cat_id === formData.cat_id)
                  .map(subcategory => (
                    <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                  ))
                }
              </select>
              <ChevronDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        )}
      </div>

      {/* Social Media */}
      <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2">
        <div className="mb-4 sm:mb-0">
          <label htmlFor="facebook" className="block text-sm font-medium text-gray-700 mb-2">Facebook</label>
          <input
            type="url"
            id="facebook"
            name="facebook"
            value={formData.facebook}
            onChange={(e) => handleSocialMediaChange(e, 'facebook')}
            disabled={isDisabled}
            placeholder="username or full URL"
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>

        <div className="mb-4 sm:mb-0">
          <label htmlFor="instagram" className="block text-sm font-medium text-gray-700 mb-2">Instagram</label>
          <input
            type="url"
            id="instagram"
            name="instagram"
            value={formData.instagram}
            onChange={(e) => handleSocialMediaChange(e, 'instagram')}
            disabled={isDisabled}
            placeholder="username or full URL"
            className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
          />
        </div>
      </div>

      {/* Google Maps URL */}
      <div className="mb-4 sm:mb-0">
        <label htmlFor="google_maps_url" className="block text-sm font-medium text-gray-700 mb-2">Google Maps URL</label>
        <input
          type="url"
          id="google_maps_url"
          name="google_maps_url"
          value={formData.google_maps_url}
          onChange={handleGoogleMapsUrlChange}
          disabled={isDisabled}
          className="mt-1 sm:mt-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-500"
        />
      </div>

      {/* Photo Upload */}
      <div className="mb-4 sm:mb-0">
        <label htmlFor="photo" className="block text-sm font-medium text-gray-700 mb-2">Photo</label>
        <input
          type="file"
          id="photo"
          name="photo"
          onChange={handlePhotoChange}
          disabled={isDisabled}
          accept="image/*"
          className="mt-1 sm:mt-0 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-black file:text-white
            hover:file:bg-gray-700
            disabled:opacity-50 disabled:cursor-not-allowed"
        />
        <p className="mt-2 text-sm text-gray-500">
          Accepted formats: JPG, PNG, WEBP. Max size: 10MB. Photos will be optimized for web use.
        </p>
      </div>

      {/* Form Actions */}
      <div className="flex justify-between mt-6">
        <button
          type="button"
          onClick={resetForm}
          className="py-2 px-4 bg-red-500 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Reset Form
        </button>
        <button
          type="submit"
          disabled={isDisabled}
          className={`py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          }`}
        >
          Submit Recommendation
        </button>
      </div>
    </form>
  );
}

export default React.memo(PublicRecommendForm);