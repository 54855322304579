import { useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { getProvinceForMapUrl } from '../utils/provinceUtils';

export function useProvinceUrlSync(selectedProvince, setSelectedProvince) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const provinceFromUrl = searchParams.get('province');
    
    if (provinceFromUrl && provinceFromUrl !== selectedProvince) {
      setSelectedProvince(provinceFromUrl);
    } else if (!provinceFromUrl) {
      const preferredProvince = getProvinceForMapUrl();
      setSelectedProvince(preferredProvince);
      const newSearch = new URLSearchParams(location.search);
      newSearch.set('province', preferredProvince);
      navigate({
        pathname: location.pathname,
        search: newSearch.toString()
      }, { replace: true });
    } else if (selectedProvince && selectedProvince !== provinceFromUrl) {
      const newSearch = new URLSearchParams(location.search);
      newSearch.set('province', selectedProvince);
      navigate({
        pathname: location.pathname,
        search: newSearch.toString()
      }, { replace: true });
    }
  }, [searchParams, selectedProvince, setSelectedProvince, navigate, location]);

  return selectedProvince;
}