import React from 'react';
import Layout from '../components/Layout';
import Announcement from '../components/Announcement';

function PrivacyPolicy() {
  const currentDate = new Date().toLocaleString('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  });

  return (
    <>
      <Announcement />
      <Layout>
        <h1 className="text-3xl font-bold mb-6">AltGrocery Privacy Policy</h1>
        <div className="prose max-w-none">
          <p>Last updated: {currentDate}</p>

          <p>At AltGrocery, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services.</p>

          <h2>1. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li><strong>Personal Information:</strong> This may include your name, email address, phone number, and location when you create an account or use our services.</li>
            <li><strong>Usage Data:</strong> We collect information on how you interact with our website, such as pages visited, time spent on the site, and other analytics data.</li>
            <li><strong>User-Generated Content:</strong> Any reviews, ratings, or comments you post on our platform.</li>
            <li><strong>Location Data:</strong> With your consent, we may collect and process information about your actual location to provide location-based services.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the collected information for various purposes, including:</p>
          <ul>
            <li>Providing and maintaining our services</li>
            <li>Improving and personalizing user experience</li>
            <li>Analyzing usage of our website</li>
            <li>Communicating with you about our services, updates, and promotional offers</li>
            <li>Responding to your inquiries and support requests</li>
            <li>Enforcing our terms, conditions, and policies</li>
          </ul>

          <h2>3. Data Sharing and Disclosure</h2>
          <p>We may share your information in the following circumstances:</p>
          <ul>
            <li><strong>With Service Providers:</strong> We may share your information with third-party service providers that perform services on our behalf, such as hosting, data analysis, and customer service.</li>
            <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
            <li><strong>With Your Consent:</strong> We may share your information with third parties when we have your consent to do so.</li>
          </ul>

          <h2>4. Data Security</h2>
          <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

          <h2>5. Your Data Protection Rights</h2>
          <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
          <ul>
            <li>The right to access, update, or delete your information</li>
            <li>The right to rectification</li>
            <li>The right to object to processing</li>
            <li>The right of restriction</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>

          <h2>6. Children's Privacy</h2>
          <p>Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to remove such information.</p>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

          <h2>8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <p>
            AltGrocery, Pixel Mixel Inc.<br />
            hello@altgrocery.ca<br />
            Erin, Ontario, Canada
          </p>
        </div>
      </Layout>
    </>
  );
}

export default PrivacyPolicy;