import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import appleIcon from '../assets/apple.png';

const Share = ({ place, onClose }) => {
  const [showQR, setShowQR] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  useEffect(() => {
    console.log('Share component received place:', place);
    if (place) {
      const url = new URL(`${window.location.origin}/map`);
      if (place.province) url.searchParams.set('province', place.province);
      if (place.city) url.searchParams.set('city', place.city);
      if (place.category) url.searchParams.set('category', place.category);
      if (place.sub_category) url.searchParams.set('subCategory', place.sub_category);
      const generatedUrl = url.toString();
      console.log('Generated share URL:', generatedUrl);
      setShareUrl(generatedUrl);
    }
  }, [place]);

  const shareToSocial = (platform) => {
    console.log(`Sharing to ${platform}`);
    let platformShareUrl;
    switch (platform) {
      case 'facebook':
        platformShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'instagram':
        // Instagram doesn't have a direct share URL, so we'll just copy the link
        navigator.clipboard.writeText(shareUrl);
        alert('URL copied to clipboard. You can now paste it into Instagram.');
        return;
      case 'tiktok':
        // TikTok doesn't have a direct share URL, so we'll just copy the link
        navigator.clipboard.writeText(shareUrl);
        alert('URL copied to clipboard. You can now paste it into TikTok.');
        return;
      case 'email':
        platformShareUrl = `mailto:?subject=Check out this place&body=${encodeURIComponent(shareUrl)}`;
        break;
      default:
        return;
    }
    window.open(platformShareUrl, '_blank');
  };

  const downloadQR = () => {
    console.log('Downloading QR code');
    const canvas = document.getElementById('qr-code');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const printQR = () => {
    console.log('Printing QR code');
    const canvas = document.getElementById('qr-code');
    const win = window.open('');
    win.document.write(`<img src="${canvas.toDataURL()}" onload="window.print();window.close()" />`);
    win.focus();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-[9999]">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={() => {
            console.log('Closing share modal');
            onClose();
          }}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Share Place</h2>
        <input
          type="text"
          value={shareUrl}
          readOnly
          className="w-full p-2 border rounded mb-4"
        />
        <div className="flex justify-between mb-4">
          <button onClick={() => shareToSocial('facebook')} className="bg-blue-600 text-white px-4 py-2 rounded">Facebook</button>
          <button onClick={() => shareToSocial('instagram')} className="bg-pink-600 text-white px-4 py-2 rounded">Instagram</button>
          <button onClick={() => shareToSocial('tiktok')} className="bg-black text-white px-4 py-2 rounded">TikTok</button>
          <button onClick={() => shareToSocial('email')} className="bg-red-600 text-white px-4 py-2 rounded">Email</button>
        </div>
        <button onClick={() => {
          console.log(`${showQR ? 'Hiding' : 'Showing'} QR code`);
          setShowQR(!showQR);
        }} className="w-full bg-green-600 text-white px-4 py-2 rounded mb-4">
          {showQR ? 'Hide' : 'Show'} QR Code
        </button>
        {showQR && (
          <div className="text-center">
            <div className="relative inline-block">
              <QRCode
                id="qr-code"
                value={shareUrl}
                size={200}
                className="mx-auto mb-4"
                imageSettings={{
                  src: appleIcon,
                  x: null,
                  y: null,
                  height: 42,
                  width: 40,
                  excavate: true,
                }}
              />
            </div>
            <div className="flex justify-between">
              <button onClick={downloadQR} className="bg-blue-500 text-white px-4 py-2 rounded">Download QR</button>
              <button onClick={printQR} className="bg-gray-500 text-white px-4 py-2 rounded">Print QR</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Share;