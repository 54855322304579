import React from 'react';
import mobileImage from '../assets/1695030790.webp';
import mobileImage2 from '../assets/1695030942.png';

const LogoCloud = () => {
  return (
    <div className="bg-white pt-16 pb-32">
      <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ maxWidth: '97rem' }}>
        <h2 className="text-center text-2xl font-semibold leading-8 text-gray-900 mb-8">
         Innovative collaborators shaping AltGrocery — always welcoming more voices.
        </h2>
        <div className="mx-auto flex flex-col sm:flex-row justify-center items-center gap-8 sm:gap-x-16 flex-wrap">
          <a 
            href="https://theccfe.org/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex justify-center items-center transition-transform duration-300 hover:scale-110"
            title="Canadian Centre for Food & Ecology"
          >
            <img
              alt="CCFE"
              src="/CCFE_signature_horizontal_colour.svg"
              className="max-h-20 w-auto object-contain"
            />
          </a>
          <div 
            className="flex justify-center items-center transition-transform duration-300 hover:scale-110"
            title="CFFS-CPSA"
          >
            <img
              alt="CFFS-CPSA"
              src="/cffs-cpsa.webp"
              className="max-h-20 w-auto object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MarketingSection = () => {
    const iconColor = "#000000"; // Black color for all icons
    const bgColor = "bg-[#F7CD65]"; // Custom yellow background for icon containers

    return (
        <section className="py-24 space-y-32">
            <LogoCloud />
            
            <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ maxWidth: '97rem' }}>
                <div className="mb-16 text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-5">What is Altgrocery?</h2>
                    <p className="mt-3 text-gray-600 text-base" style={{ fontSize: '1rem', maxWidth: '38rem', margin: '0 auto' }}>
                    AltGrocery is a platform that connects consumers to alternative grocery options, such as local farms, farmers' markets, and specialty independent food stores.
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-28">
                    <div className="w-full lg:w-2/5">
                        <div className="grid grid-cols-1 gap-12 max-lg:max-w-2xl max-lg:mx-auto">
                            {[
                                { title: "Crowdsourced Recommendations", description: "Discover hidden gems nationwide with community-curated suggestions.", icon: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" },
                                { title: "Advanced Filtering", description: "Easily find what you need by country, province, category, and sub-category.", icon: "M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" },
                                { title: "Support Local Agriculture", description: "Connect with farmers, farmer's markets, and independent grocers in your area.", icon: "M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" },
                                { title: "Free Digital Presence", description: "Enabling small local businesses to establish an online footprint at no cost.", icon: "M13 10V3L4 14h7v7l9-11h-7z" },
                            ].map((item, index) => (
                                <div key={index} className="flex gap-4">
                                    <div className={`flex items-center justify-center w-16 h-16 ${bgColor} rounded-full flex-shrink-0`}>
                                        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke={iconColor}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                                        </svg>
                                    </div>
                                    <div className="flex-grow">
                                        <h4 className="mb-2 text-lg font-semibold text-gray-900">{item.title}</h4>
                                        <p className="text-sm text-gray-600">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/5">
                        <img src={mobileImage2} className="mx-auto rounded-3xl object-cover" alt="Mobile app screenshot" />
                    </div>
                </div>
            </div>

            <div className="mx-auto px-4 sm:px-6 lg:px-8" style={{ maxWidth: '97rem' }}>
                <div className="mb-16 text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-5">Our upgraded features</h2>
                    <p className="mt-3 text-gray-600 text-base" style={{ fontSize: '1rem', maxWidth: '38rem', margin: '0 auto' }}>
                        Introducing a more robust community experience using AltGrocery 2.0
                    </p>
                </div>
                <div className="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-8 gap-12 lg:gap-8 items-center">
                    <div className="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
                        <div className="grid grid-cols-1 gap-12">
                            {[
                                { title: "Mobile-Friendly", description: "Our platform has been rebuilt with a focus on mobile, ensuring easy navigation through our map on any device.", icon: "M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" },
                                { title: "Sharing", description: "Easily share listings across social media, email, or by generating custom QR codes that link directly to your a listing profile.", icon: "M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" },
                                { title: "Favourites & Followers", description: "Follow or like your preferred grocers and growers, creating a personalized list of favorites to stay updated with their offerings.", icon: "M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" },
                            ].map((item, index) => (
                                <div key={index} className="flex gap-4">
                                    <div className={`flex items-center justify-center w-16 h-16 ${bgColor} rounded-full flex-shrink-0`}>
                                        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke={iconColor}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                                        </svg>
                                    </div>
                                    <div className="flex-grow">
                                        <h4 className="mb-2 text-lg font-semibold text-gray-900">{item.title}</h4>
                                        <p className="text-sm text-gray-600">{item.description}</p>
                                        {item.title === "Favourites & Followers" && (
                                            <p className="text-xs text-gray-500 mt-2">**Upcoming features personalized recommendations & deals**</p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="lg:col-span-4 md:col-span-12 lg:order-2 order-1">
                        <img src={mobileImage} className="mx-auto rounded-3xl object-cover" alt="Mobile app features" />
                    </div>
                    <div className="lg:col-span-4 md:col-span-6 order-3">
                        <div className="grid grid-cols-1 gap-12">
                            {[
                                { title: "Localization", description: "No endless scrolling, we can auto-detect your country to filter your data quickly by province/state.", icon: "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" },
                                { title: "Recommendations", description: "After signing in, you are able to recommend your fave grocers or farms.", icon: "M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" },
                                { title: "Manage Listings", description: "Owners of grocers & farms etc., will have the ability to now manage, edit, update their listings via dashboard.", icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" },
                            ].map((item, index) => (
                                <div key={index} className="flex gap-4">
                                    <div className={`flex items-center justify-center w-16 h-16 ${bgColor} rounded-full flex-shrink-0`}>
                                        <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke={iconColor}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                                        </svg>
                                    </div>
                                    <div className="flex-grow">
                                        <h4 className="mb-2 text-lg font-semibold text-gray-900">{item.title}</h4>
                                        <p className="text-sm text-gray-600">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MarketingSection;