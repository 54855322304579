import { useState, useCallback, useEffect, useMemo } from 'react';
import { supabase } from '../config/supabaseClient';
import toast from 'react-hot-toast';
import debounce from 'lodash/debounce';
import imageCompression from 'browser-image-compression';

export const usePublicRecommendForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    unit: '',
    postal_code: '',
    city: '',
    country: '',
    country_id: null,
    province: '',
    prov_id: null,
    description: '',
    website: '',
    phone: '',
    email: '',
    category: '',
    cat_id: null,
    sub_category: '',
    sub_cat_id: null,
    facebook: '',
    instagram: '',
    google_maps_url: '',
    photo: null,
    lat: null,
    lng: null,
  });

  const [nameError, setNameError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [bannedStores, setBannedStores] = useState([]);
  const [isGoogleMapsApiWorking, setIsGoogleMapsApiWorking] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const [categoriesData, subcategoriesData, countriesData, provincesData, bannedStoresData] = await Promise.all([
        supabase.from('categories').select('*'),
        supabase.from('subcategories').select('*'),
        supabase.from('countries').select('*'),
        supabase.from('provinces').select('*'),
        supabase.from('ban').select('store_name'),
      ]);

      setCategories(categoriesData.data || []);
      setSubcategories(subcategoriesData.data || []);
      setCountries(countriesData.data || []);
      setProvinces(provincesData.data || []);
      setBannedStores(bannedStoresData.data?.map(item => item.store_name.toLowerCase()) || []);
      setIsLoading(false);
      
      await checkGoogleMapsApiKey();
    };

    fetchData();
  }, []);

  const checkGoogleMapsApiKey = async () => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    
    if (!apiKey) {
      setIsGoogleMapsApiWorking(false);
      toast.error('Google Maps API key is not configured');
      return;
    }

    const testAddress = 'Toronto, ON, Canada';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(testAddress)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (data.status === 'OK') {
        setIsGoogleMapsApiWorking(true);
      } else {
        setIsGoogleMapsApiWorking(false);
        toast.error('There was an issue with the Google Maps API. Please try again later.');
      }
    } catch (error) {
      setIsGoogleMapsApiWorking(false);
      toast.error('Unable to verify Google Maps API key. Please try again later.');
    }
  };

  const getCoordinates = useCallback(async (address, unit, city, province, postalCode, country) => {
    if (!isGoogleMapsApiWorking) {
      console.log('Google Maps API is not working');
      return { lat: null, lng: null, googleMapsUrl: null };
    }

    let fullAddress = `${address}, ${city}, ${province} ${postalCode}, ${country}`;
    if (unit) {
      fullAddress = `${unit} ${fullAddress}`;
    }
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    
    if (!apiKey) {
      console.log('Google Maps API key is missing');
      return { lat: null, lng: null, googleMapsUrl: null };
    }

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${apiKey}`;

    try {
      console.log('Fetching coordinates for address:', fullAddress);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Geocoding API response:', data);

      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const placeId = data.results[0].place_id;
        const googleMapsUrl = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
        console.log('Coordinates found:', { lat, lng, googleMapsUrl });
        return { 
          lat: lat,
          lng: lng,
          googleMapsUrl 
        };
      } else {
        console.log('No results found for the given address');
        return { lat: null, lng: null, googleMapsUrl: null };
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return { lat: null, lng: null, googleMapsUrl: null };
    }
  }, [isGoogleMapsApiWorking]);

  const updateLatLng = useCallback(async () => {
    const { address, unit, city, province, postal_code, country } = formData;
    if (address && city && province && country) {
      try {
        const { lat, lng, googleMapsUrl } = await getCoordinates(address, unit, city, province, postal_code, country);
        console.log('Updating form data with coordinates:', { lat, lng, googleMapsUrl });
        setFormData(prevData => ({
          ...prevData,
          lat,
          lng,
          google_maps_url: googleMapsUrl
        }));
      } catch (error) {
        console.error('Error fetching coordinates:', error);
        toast.error('Unable to find coordinates for the given address. Please check the address and try again.');
      }
    }
  }, [formData, getCoordinates]);

  const debouncedUpdateLatLng = useMemo(() => debounce(updateLatLng, 500), [updateLatLng]);

  const checkForDuplicates = useCallback(async (name, address, city) => {
    const { data: placesData, error: placesError } = await supabase
      .from('places')
      .select('*')
      .ilike('name', name)
      .ilike('address', address)
      .ilike('city', city);
  
    const { data: recommendationsData, error: recommendationsError } = await supabase
      .from('recommendations')
      .select('*')
      .ilike('name', name)
      .ilike('address', address)
      .ilike('city', city);
  
    if (placesError || recommendationsError) {
      console.error('Error checking for duplicates:', placesError || recommendationsError);
      return false;
    }
  
    const combinedData = [...(placesData || []), ...(recommendationsData || [])];
  
    if (combinedData.length > 0) {
      const errorMessage = 'This business already exists in our database.';
      setDuplicateError(errorMessage);
      setIsDisabled(true);
      toast.error(errorMessage);
      return true;
    }
  
    setDuplicateError('');
    setIsDisabled(false);
    return false;
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData, [name]: value };

      if (name === 'category') {
        const selectedCategory = categories.find(cat => cat.id.toString() === value);
        newData.cat_id = selectedCategory ? selectedCategory.id : null;
        newData.category = selectedCategory ? selectedCategory.name : '';
        newData.sub_category = '';
        newData.sub_cat_id = null;
      }

      if (name === 'sub_category') {
        const selectedSubCategory = subcategories.find(subcat => subcat.id.toString() === value);
        newData.sub_cat_id = selectedSubCategory ? selectedSubCategory.id : null;
        newData.sub_category = selectedSubCategory ? selectedSubCategory.name : '';
      }

      if (name === 'country') {
        const selectedCountry = countries.find(c => c.name === value);
        newData.country = value;
        newData.country_id = selectedCountry ? selectedCountry.id : null;
        newData.province = '';
        newData.prov_id = null;
        const newFilteredProvinces = provinces.filter(province => province.country_id === selectedCountry?.id);
        setFilteredProvinces(newFilteredProvinces);
      }

      if (name === 'province') {
        const selectedProvince = provinces.find(p => p.name === value);
        newData.province = value;
        newData.prov_id = selectedProvince ? selectedProvince.id : null;
      }

      return newData;
    });

    if (name === 'name') {
      const lowercaseName = value.toLowerCase();
      if (bannedStores.includes(lowercaseName)) {
        const errorMessage = 'This business name is not allowed.';
        setNameError(errorMessage);
        setIsDisabled(true);
        toast.error(errorMessage);
      } else {
        setNameError('');
        setIsDisabled(false);
      }
    }

    // Update lat and lng when address, city, province, or country changes
    if (['address', 'city', 'province', 'country', 'postal_code'].includes(name)) {
      debouncedUpdateLatLng();
    }

    // Check for duplicates when name, address, or city changes
    if (['name', 'address', 'city'].includes(name)) {
      const updatedFormData = { ...formData, [name]: value };
      if (updatedFormData.name && updatedFormData.address && updatedFormData.city) {
        checkForDuplicates(updatedFormData.name, updatedFormData.address, updatedFormData.city);
      }
    }
  }, [bannedStores, provinces, countries, categories, subcategories, debouncedUpdateLatLng, checkForDuplicates, formData]);

  const optimizeAndUploadImage = async (imageFile, slug) => {
    const options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 664,
      useWebWorker: true,
      fileType: 'image/webp',
    };

    try {
      let compressedFile = await imageCompression(imageFile, options);

      if (compressedFile.size > 51200) {
        const stricterOptions = {
          ...options,
          maxSizeMB: 0.049,
          maxWidthOrHeight: 600,
        };
        compressedFile = await imageCompression(compressedFile, stricterOptions);
      }

      const fileName = `${slug}.webp`;
      const filePath = `public/${fileName}`;
      
      const { error: uploadError } = await supabase.storage
        .from('photos')
        .upload(filePath, compressedFile, {
          contentType: 'image/webp',
          upsert: true
        });

      if (uploadError) {
        console.error('Error uploading file:', uploadError);
        throw uploadError;
      }

      const { data: urlData, error: urlError } = supabase.storage
        .from('photos')
        .getPublicUrl(filePath);

      if (urlError) {
        console.error('Error getting public URL:', urlError);
        throw urlError;
      }

      console.log('Photo uploaded successfully:', urlData.publicUrl);
      return urlData.publicUrl;
    } catch (error) {
      console.error('Error in optimizeAndUploadImage:', error);
      throw error;
    }
  };

  const handleWebsiteChange = useCallback((e) => {
    let value = e.target.value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData((prevData) => ({
      ...prevData,
      website: value,
    }));
  }, []);

  const handleSocialMediaChange = useCallback((e, platform) => {
    let value = e.target.value;
    if (value && !value.includes('.com')) {
      value = `https://www.${platform}.com/${value}`;
    } else if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData((prevData) => ({
      ...prevData,
      [platform]: value,
    }));
  }, []);

  const handlePhoneChange = useCallback((e) => {
    const value = e.target.value;
    const formattedValue = value.replace(/\D/g, '');
    setFormData((prevData) => ({
      ...prevData,
      phone: formattedValue,
    }));
  }, []);

  const handlePostalCodeChange = useCallback((e) => {
    const value = e.target.value.toUpperCase();
    setFormData((prevData) => ({
      ...prevData,
      postal_code: value,
    }));
  }, []);

  const handleEmailChange = useCallback((e) => {
    const value = e.target.value.toLowerCase();
    setFormData((prevData) => ({
      ...prevData,
      email: value,
    }));
  }, []);

  const handleGoogleMapsUrlChange = useCallback((e) => {
    let value = e.target.value;
    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
      value = 'https://' + value;
    }
    setFormData((prevData) => ({
      ...prevData,
      google_maps_url: value,
    }));
  }, []);

  const handlePhotoChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      toast.error('File size exceeds 10MB limit');
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
  }, []);

  const resetForm = useCallback(() => {
    setFormData({
      name: '',
      address: '',
      unit: '',
      postal_code: '',
      city: '',
      country: '',
      country_id: null,
      province: '',
      prov_id: null,
      description: '',
      website: '',
      phone: '',
      email: '',
      category: '',
      cat_id: null,
      sub_category: '',
      sub_cat_id: null,
      facebook: '',
      instagram: '',
      google_maps_url: '',
      photo: null,
      lat: null,
      lng: null,
    });
    setNameError('');
    setDuplicateError('');
    setIsDisabled(false);
    setFilteredProvinces([]);
  }, []);

  const validateForm = useCallback(() => {
    const requiredFields = ['name', 'address', 'city', 'country', 'province', 'cat_id'];
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0) {
      toast.error(`Please fill in all required fields: ${missingFields.join(', ')}`);
      return false;
    }
    
    return true;
  }, [formData]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isDisabled) {
      if (nameError) {
        toast.error(nameError);
      } else if (duplicateError) {
        toast.error(duplicateError);
      } else {
        toast.error('Please correct the errors before submitting.');
      }
      return;
    }
    
    if (!validateForm()) {
      return;
    }
    
    setIsDisabled(true);

    try {
      const slug = `${formData.name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '')}-${Date.now()}`;

      let photoUrl = null;
      if (formData.photo) {
        try {
          photoUrl = await optimizeAndUploadImage(formData.photo, slug);
        } catch (error) {
          console.error('Error uploading photo:', error);
          toast.error(`Error uploading photo: ${error.message}`);
          setIsDisabled(false);
          return;
        }
      }

      const { error } = await supabase
        .from('recommendations')
        .insert([
          {
            ...formData,
            photo: photoUrl,
            approved: false,
            claimed: false,
            slug,
          },
        ]);

      if (error) throw error;

      toast.success('Thank you for your recommendation! It has been submitted for approval.');
      resetForm();
    } catch (error) {
      console.error('Error submitting recommendation:', error);
      toast.error('There was an error submitting your recommendation. Please try again.');
    } finally {
      setIsDisabled(false);
    }
  }, [formData, resetForm, isDisabled, validateForm, nameError, duplicateError]);

  return {
    formData,
    handleChange,
    handleWebsiteChange,
    handleSocialMediaChange,
    handlePhoneChange,
    handlePostalCodeChange,
    handleEmailChange,
    handleGoogleMapsUrlChange,
    handlePhotoChange,
    handleSubmit,
    resetForm,
    categories,
    subcategories,
    countries,
    provinces: filteredProvinces,
    nameError,
    duplicateError,
    isDisabled,
    isLoading,
  };
};