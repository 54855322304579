// components/Announcement.js
import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { useState, useEffect } from 'react';

export default function Announcement() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const announcementClosed = sessionStorage.getItem('announcementClosed');
    setVisible(announcementClosed !== 'true');
  }, []);

  const handleClose = () => {
    setVisible(false);
    sessionStorage.setItem('announcementClosed', 'true');
  };

  if (!visible) return null;

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-[#f7cd65] px-6 py-2.5 sm:px-3.5 sm:before:flex-1 shadow-[inset_0_-1px_3px_rgba(0,0,0,0.1)]">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900 m-0">
          <strong className="font-semibold">Support our Growth:</strong>
            &nbsp; Help us improve AltGrocery 2.0 with your donation!
        </p>
        <a
          href="https://buymeacoffee.com/altgrocery"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Donate now <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={handleClose}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
