import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { setLastSelectedProvince } from '../utils/provinceUtils';

function SecondaryNav({ 
  onFilterChange, 
  isMobile, 
  isMenuOpen, 
  setIsMenuOpen,
  filters, 
  categories, 
  subCategories, 
  onCategoryChange, 
  onSubCategoryChange, 
  resetFilters, 
  detectedProvince,
  onProvinceChange,
  provinces,
  cities,
  onCityChange
}) {
  const [citySearch, setCitySearch] = useState('');
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [hoveredCity, setHoveredCity] = useState(null);
  const citySearchRef = useRef(null);
  const navRef = useRef(null);

  const filteredCities = useMemo(() => {
    return cities.filter(city => 
      city && city.toLowerCase().includes(citySearch.toLowerCase())
    );
  }, [cities, citySearch]);

  const handleFilterChange = useCallback((key, value) => {
    const newFilters = { ...filters, [key]: value };
    if (key === 'province') {
      newFilters.city = '';
      setCitySearch('');
    }
    if (key === 'city') {
      newFilters.category = '';
      newFilters.subCategory = '';
    }
    if (key === 'category') {
      newFilters.subCategory = '';
    }
    onFilterChange(newFilters);
  }, [filters, onFilterChange]);

  const handleCityChange = useCallback((city) => {
    handleFilterChange('city', city);
    setCitySearch(city);
    setShowCityDropdown(false);
    onCityChange(city);
  }, [handleFilterChange, onCityChange]);

  const handleCityClear = useCallback(() => {
    handleFilterChange('city', '');
    setCitySearch('');
    setShowCityDropdown(true);
  }, [handleFilterChange]);

  const handleProvinceChange = useCallback((province) => {
    handleFilterChange('province', province);
    onProvinceChange(province);
    if (province !== '') {
      setLastSelectedProvince(province);
    }
  }, [handleFilterChange, onProvinceChange]);

  const handleCategoryChange = useCallback((categoryName) => {
    if (categoryName === filters.category) {
      handleFilterChange('category', '');
      onCategoryChange('');
    } else {
      handleFilterChange('category', categoryName);
      onCategoryChange(categoryName);
    }
  }, [handleFilterChange, onCategoryChange, filters.category]);

  const handleSubCategoryChange = useCallback((subCategoryName) => {
    if (subCategoryName === filters.subCategory) {
      handleFilterChange('subCategory', '');
      onSubCategoryChange('');
    } else {
      handleFilterChange('subCategory', subCategoryName);
      onSubCategoryChange(subCategoryName);
    }
  }, [handleFilterChange, onSubCategoryChange, filters.subCategory]);

  const handleReset = useCallback(() => {
    resetFilters(true);
    setCitySearch('');
    if (isMobile) {
      setIsMenuOpen(false);
    }
  }, [resetFilters, isMobile, setIsMenuOpen]);

  const filteredCategories = useMemo(() => {
    return categories.filter(category => category != null);
  }, [categories]);

  useEffect(() => {
    if (filters.city) {
      setCitySearch(filters.city);
    } else {
      setCitySearch('');
    }
  }, [filters.city]);

  const commonSelectClass = "appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded focus:outline-none focus:bg-white focus:border-gray-500 text-sm";
  const commonWrapperClass = "relative inline-block w-full";
  const commonArrowClass = "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700";

  const isCityDisabled = filters.province === '' || filters.province === 'All Provinces';
  const hasSubCategories = subCategories.length > 0;

  const renderClearButton = (value, onClear) => (
    value && (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClear();
        }}
        className="absolute inset-y-0 right-8 flex items-center pr-2 cursor-pointer group"
      >
        <div className="relative p-1 rounded-full group-hover:bg-red-500 transition-colors duration-200">
          <XMarkIcon className="h-4 w-4 text-gray-500 group-hover:text-white" aria-hidden="true" />
          <div className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            Remove choice
          </div>
        </div>
      </div>
    )
  );

  const renderSubCategoryDropdown = () => (
    <div className={`${commonWrapperClass} flex-1`}>
      <div className="relative">
        <select
          id="subcategory-select"
          value={filters.subCategory || ''}
          onChange={(e) => handleSubCategoryChange(e.target.value)}
          className={`${commonSelectClass} ${!hasSubCategories ? 'bg-gray-100 text-gray-500' : ''}`}
          aria-label="Select Sub-Category"
          disabled={!hasSubCategories}
        >
          {hasSubCategories ? (
            <>
              <option value="">Select Sub-Category</option>
              {subCategories.map(sub_category => sub_category && (
                <option key={sub_category.id} value={sub_category.name}>{sub_category.name}</option>
              ))}
            </>
          ) : (
            <option value="">No sub-categories for this category</option>
          )}
        </select>
        {renderClearButton(filters.subCategory, () => handleSubCategoryChange(''))}
        <div className={commonArrowClass}>
          <FontAwesomeIcon icon={faChevronDown} className={`h-4 w-4 dropdown-chevron ${!hasSubCategories ? 'text-gray-400' : ''}`} aria-hidden="true" />
        </div>
      </div>
    </div>
  );

  const renderCategoryDropdown = () => (
    <div className={`${commonWrapperClass} flex-1`}>
      <div className="relative">
        <select
          id="category-select"
          value={filters.category || ''}
          onChange={(e) => handleCategoryChange(e.target.value)}
          className={commonSelectClass}
          aria-label="Select Category"
        >
          <option value="">Select Category</option>
          {filteredCategories.map(category => category && (
            <option key={category.id} value={category.name}>{category.name}</option>
          ))}
        </select>
        {renderClearButton(filters.category, () => handleCategoryChange(''))}
        <div className={commonArrowClass}>
          <FontAwesomeIcon icon={faChevronDown} className="h-4 w-4 dropdown-chevron" aria-hidden="true" />
        </div>
      </div>
    </div>
  );

  const renderMobileContent = () => (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto pb-32">
        <div className="flex flex-col space-y-4 p-4">
          <div className={commonWrapperClass}>
            <select
              value={filters.province || ''}
              onChange={(e) => handleProvinceChange(e.target.value)}
              className={commonSelectClass}
              aria-label="Select Province"
            >
              <option value="">All Provinces</option>
              {provinces.map(province => province && (
                <option key={province} value={province}>{province}</option>
              ))}
            </select>
            <div className={commonArrowClass}>
              <FontAwesomeIcon icon={faChevronDown} className="h-4 w-4 dropdown-chevron" aria-hidden="true" />
            </div>
          </div>

          <div className={commonWrapperClass}>
            <Combobox
              as="div"
              value={citySearch}
              onChange={handleCityChange}
              disabled={isCityDisabled}
            >
              <div className="relative">
                <Combobox.Input
                  className={`${commonSelectClass} pr-8 ${isCityDisabled ? 'bg-gray-200 cursor-not-allowed' : ''}`}
                  onChange={(event) => setCitySearch(event.target.value)}
                  displayValue={() => citySearch}
                  placeholder={isCityDisabled ? "Select a province first" : "Search city"}
                  aria-label="Search City"
                  disabled={isCityDisabled}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {citySearch && !isCityDisabled ? (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCityClear();
                      }}
                      className="relative p-1 rounded-full hover:bg-red-500 transition-colors duration-200 group"
                    >
                      <XMarkIcon className="h-4 w-4 text-gray-500 group-hover:text-white" aria-hidden="true" />
                      <div className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                        Remove choice
                      </div>
                    </div>
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} className={`h-4 w-4 text-gray-700 dropdown-chevron ${isCityDisabled ? 'opacity-50' : ''}`} aria-hidden="true" />
                  )}
                </Combobox.Button>
              </div>
              {!isCityDisabled && (
                <Combobox.Options className="absolute z-[10001] mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {filteredCities.map(city => city && (
                    <Combobox.Option
                      key={city}
                      value={city}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-9 ${
                          active ? 'bg-[#F7CD65] text-black' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                            {city}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                active ? 'text-white' : 'text-indigo-600'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </Combobox>
          </div>

          {renderCategoryDropdown()}
          {renderSubCategoryDropdown()}
        </div>
      </div>
      <div className="fixed bottom-28 left-0 right-0 p-4 bg-white border-t border-gray-300">
        <button
          onClick={handleReset}
          className="w-full p-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300 ease-in-out"
        >
          Reset Filters
        </button>
      </div>
    </div>
  );

  return (
    <div className={`relative ${isMobile ? 'z-[9999]' : 'z-[100]'} font-medium text-black`}>
      <nav
        ref={navRef}
        className={`
          bg-white border-b border-gray-300 shadow-md
          ${isMobile ? 'fixed left-0 right-0 top-0 overflow-hidden transform transition-all duration-300 ease-in-out z-[9999]' : 'relative'}
          ${isMobile && !isMenuOpen ? 'translate-x-full opacity-0 pointer-events-none' : 'translate-x-0 opacity-100'}
          ${isMobile ? 'w-full' : 'w-full'}
        `}
        style={{
          maxHeight: isMobile ? 'calc(100vh - 60px)' : 'auto',
          top: isMobile ? '0' : 'auto',
          bottom: isMobile ? '60px' : 'auto',
        }}
      >
        {isMobile && (
          <div className="flex justify-between items-center p-4 border-b border-gray-300">
            <h2 className="text-lg font-semibold">Filters</h2>
            <button
              onClick={() => setIsMenuOpen(false)}
              className="text-gray-700 hover:text-gray-900 focus:outline-none px-3 py-1 rounded border border-gray-300"
              aria-label="Close filters"
            >
              Close Menu
            </button>
          </div>
        )}
        {isMobile ? renderMobileContent() : (
          <div className="flex flex-row justify-between items-center p-2 space-x-2">
            <div className="flex-1 flex items-center space-x-2">
              <div className={`${commonWrapperClass} flex-1`}>
                <select
                  value={filters.province || ''}
                  onChange={(e) => handleProvinceChange(e.target.value)}
                  className={commonSelectClass}
                  aria-label="Select Province"
                >
                  <option value="">All Provinces</option>
                  {provinces.map(province => province && (
                    <option key={province} value={province}>{province}</option>
                  ))}
                </select>
                <div className={commonArrowClass}>
                  <FontAwesomeIcon icon={faChevronDown} className="h-4 w-4 dropdown-chevron" aria-hidden="true" />
                </div>
              </div>

              <div ref={citySearchRef} className={`${commonWrapperClass} flex-1`}>
                <Combobox
                  as="div"
                  value={citySearch}
                  onChange={handleCityChange}
                  disabled={isCityDisabled}
                >
                  <div className="relative">
                    <Combobox.Input
                      className={`${commonSelectClass} pr-8 ${isCityDisabled ? 'bg-gray-200 cursor-not-allowed' : ''}`}
                      onChange={(event) => setCitySearch(event.target.value)}
                      displayValue={() => citySearch}
                      onFocus={() => setShowCityDropdown(true)}
                      placeholder={isCityDisabled ? "Select a province first" : "Search city"}
                      style={{
                        fontSize: '14px',
                        borderRadius: '4px',
                      }}
                      aria-label="Search City"
                      disabled={isCityDisabled}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" style={{ background: 'none' }}>
                      {citySearch && !isCityDisabled ? (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCityClear();
                          }}
                          onMouseEnter={() => setHoveredCity(citySearch)}
                          onMouseLeave={() => setHoveredCity(null)}
                          className="relative p-1 rounded-full hover:bg-red-500 transition-colors duration-200 group"
                        >
                          <XMarkIcon className="h-4 w-4 text-gray-500 group-hover:text-white" aria-hidden="true" />
                          <div className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                            Remove choice
                          </div>
                        </div>
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} className={`h-4 w-4 text-gray-700 dropdown-chevron ${isCityDisabled ? 'opacity-50' : ''}`} aria-hidden="true" />
                      )}
                    </Combobox.Button>
                  </div>
                  {showCityDropdown && !isCityDisabled && (
                    <Combobox.Options className="absolute z-[10001] mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {filteredCities.map(city => city && (
                        <Combobox.Option
                          key={city}
                          value={city}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${
                              active ? 'bg-[#F7CD65] text-black' : 'text-gray-900'
                            }`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                {city}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                    active ? 'text-white' : 'text-indigo-600'
                                  }`}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}
                </Combobox>
              </div>

              {renderCategoryDropdown()}
              {renderSubCategoryDropdown()}
            </div>

            <button
              onClick={handleReset}
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300 ease-in-out"
            >
              Reset
            </button>
          </div>
        )}
      </nav>
    </div>
  );
}

export default React.memo(SecondaryNav);