// src/hooks/useResizeObserver.js
import { useEffect, useRef, useCallback, useMemo } from 'react';
import debounce from 'lodash.debounce';

const useResizeObserver = (callback, delay = 100) => {
  const resizeObserverRef = useRef(null);

  // Create a memoized debounced function
  const debouncedCallback = useMemo(
    () => debounce((entries) => {
      requestAnimationFrame(() => {
        callback(entries);
      });
    }, delay),
    [callback, delay]
  );

  // Use useCallback to memoize the function that calls the debounced callback
  const handleResize = useCallback((entries) => {
    debouncedCallback(entries);
  }, [debouncedCallback]);

  useEffect(() => {
    const resizeObserverError = 'ResizeObserver loop completed with undelivered notifications.';
    const originalError = console.error;
    console.error = (...args) => {
      if (args.length > 0 && typeof args[0] === 'string' && args[0].startsWith(resizeObserverError)) {
        // Ignore this specific error
        return;
      }
      originalError.apply(console, args);
    };

    const resizeObserver = new ResizeObserver((entries) => {
      try {
        handleResize(entries);
      } catch (error) {
        console.warn('ResizeObserver error:', error);
      }
    });

    resizeObserverRef.current = resizeObserver;

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
      // Restore original console.error
      console.error = originalError;
      // Cancel any pending debounced calls
      debouncedCallback.cancel();
    };
  }, [handleResize, debouncedCallback]);

  const observe = useCallback((element) => {
    if (resizeObserverRef.current && element) {
      resizeObserverRef.current.observe(element);
    }
  }, []);

  const unobserve = useCallback((element) => {
    if (resizeObserverRef.current && element) {
      resizeObserverRef.current.unobserve(element);
    }
  }, []);

  return { observe, unobserve };
};

export default useResizeObserver;