import React from 'react';

const BottomNavigation = ({ activeView, toggleView, toggleFilterDrawer }) => {
  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200">
      <div className="grid h-full max-w-lg grid-cols-3 mx-auto font-medium">
        <button 
          type="button" 
          className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group ${activeView === 'listView' ? 'text-blue-600' : 'text-gray-500'}`}
          onClick={() => toggleView('listView')}
        >
          <svg className="w-5 h-5 mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 3a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H7zM4 7a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zM2 11a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-4z"/>
          </svg>
          <span className="text-sm">Cards</span>
        </button>
        <button 
          type="button" 
          className={`inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group ${activeView === 'mapView' ? 'text-blue-600' : 'text-gray-500'}`}
          onClick={() => toggleView('mapView')}
        >
          <svg className="w-5 h-5 mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 0 0 2 4v10a1 1 0 0 0 .293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0 0 18 16V6a1 1 0 0 0-.293-.707z"/>
          </svg>
          <span className="text-sm">Map</span>
        </button>
        <button 
          type="button" 
          className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group text-gray-500"
          onClick={toggleFilterDrawer}
        >
          <svg className="w-5 h-5 mb-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2.586a1 1 0 0 1-.293.707l-6.414 6.414a1 1 0 0 0-.293.707V17l-4 4v-6.586a1 1 0 0 0-.293-.707L3.293 7.293A1 1 0 0 1 3 6.586V4z"/>
          </svg>
          <span className="text-sm">Filters</span>
        </button>
      </div>
    </div>
  );
};

export default BottomNavigation;