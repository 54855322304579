import React, { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';

const CommunityContent = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openProvinces, setOpenProvinces] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: countriesData, error: countriesError } = await supabase
          .from('countries')
          .select('id, name')
          .order('name');

        if (countriesError) throw countriesError;

        const countriesWithProvinces = await Promise.all(
          countriesData.map(async (country) => {
            const { data: provinces, error: provincesError } = await supabase
              .from('provinces')
              .select('id, name')
              .eq('country_id', country.id)
              .order('name');

            if (provincesError) throw provincesError;

            const provincesWithCities = await Promise.all(
              provinces.map(async (province) => {
                const { data: cities, error: citiesError } = await supabase
                  .from('places')
                  .select('city')
                  .eq('prov_id', province.id)
                  .order('city');

                if (citiesError) throw citiesError;

                const uniqueCities = [...new Set(cities.map(city => city.city))];

                return {
                  ...province,
                  cities: uniqueCities
                };
              })
            );

            return {
              ...country,
              provinces: provincesWithCities
            };
          })
        );

        setCountries(countriesWithProvinces);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const toggleProvince = (countryId, provinceId) => {
    setOpenProvinces(prev => {
      const newState = { ...prev };
      if (!newState[countryId]) {
        newState[countryId] = provinceId;
      } else if (newState[countryId] === provinceId) {
        delete newState[countryId];
      } else {
        newState[countryId] = provinceId;
      }
      return newState;
    });
  };

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">Error: {error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold mb-8 text-center">Our Community</h1>
      <p className="text-xl text-center mb-12">
        Discover the countries, provinces/states, and cities where our alternative grocers are making a difference.
      </p>
      <div className="space-y-4">
        {countries.map((country) => (
          <Disclosure key={country.id}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>{country.name}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <div className="space-y-2">
                    {country.provinces.map((province) => (
                      <div key={province.id}>
                        <button
                          onClick={() => toggleProvince(country.id, province.id)}
                          className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                        >
                          <span>{province.name}</span>
                          <ChevronUpIcon
                            className={`${
                              openProvinces[country.id] === province.id ? 'transform rotate-180' : ''
                            } w-5 h-5 text-gray-500`}
                          />
                        </button>
                        {openProvinces[country.id] === province.id && (
                          <div className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                              {province.cities.map((city, index) => (
                                <div key={index}>
                                  {city}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default CommunityContent;