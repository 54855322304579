import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';

function NotFound() {
  useEffect(() => {
    // Set the status code to 404
    document.title = '404: Page Not Found';
    
    // This won't actually change the HTTP status code on the client side,
    // but it's a good practice for static hosting services that use this meta tag.
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('http-equiv', 'Status');
    metaTag.setAttribute('content', '404');
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-[50vh]">
        <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
        <p className="text-2xl text-gray-600 mb-8">Oops! Page not found.</p>
        <Link to="/" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Go Home
        </Link>
      </div>
    </Layout>
  );
}

export default NotFound;