import { useEffect } from 'react';
import axios from 'axios';
import { useLocationContext } from '../context/LocationContext';

const CountryDetector = () => {
  const { location, updateLocation } = useLocationContext();

  useEffect(() => {
    const detectLocation = async () => {
      try {
        // Check if the location is already set
        if (location.country_code && location.province) {
          return;
        }

        // Check if the location is stored in localStorage
        const storedCountryCode = localStorage.getItem('country_code');
        const storedCountryName = localStorage.getItem('countryName');
        const storedProvince = localStorage.getItem('province');

        if (storedCountryCode && storedCountryName && storedProvince) {
          updateLocation({
            country_code: storedCountryCode,
            countryName: storedCountryName,
            province: storedProvince,
          });
          return;
        }

        // If not, use an API to detect the user's location
        const response = await axios.get('https://ipapi.co/json/');
        const country_code = response.data.country_code.toLowerCase();
        const countryName = response.data.country_name.toLowerCase();
        const province = response.data.region.toLowerCase();

        // Store the detected location
        localStorage.setItem('country_code', country_code);
        localStorage.setItem('countryName', countryName);
        localStorage.setItem('province', province);

        // Update the location context
        updateLocation({
          country_code,
          countryName,
          province,
        });
      } catch (error) {
        console.error('Error detecting location:', error);
      }
    };

    detectLocation();
  }, [location, updateLocation]);

  return null; // No UI needed
};

export default CountryDetector;
