import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../config/supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useLocationContext } from '../context/LocationContext';
import { useAuth } from '../context/AuthContext';

function SignIn() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { location } = useLocationContext();
  const { user } = useAuth();

  console.log('SignIn component rendered');

  const handleAuthChange = useCallback((event, session) => {
    console.log('Auth state changed:', event, session);
    if (event === 'SIGNED_IN') {
      let countryCode = location.country_code || 'ca'; // Default to 'ca' if no country code is available
      console.log('Redirecting to:', `/${countryCode}`);
      navigate(`/${countryCode}`);
      
      // Check if the toast has already been shown this session
      if (!sessionStorage.getItem('signInToastShown')) {
        toast.success('Successfully signed in!', { id: 'signin-toast' });
        sessionStorage.setItem('signInToastShown', 'true');
      }
    }
  }, [navigate, location]);

  useEffect(() => {
    console.log('Setting up auth listener');
    const { data: authListener } = supabase.auth.onAuthStateChange(handleAuthChange);

    return () => {
      console.log('Cleaning up auth listener');
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, [handleAuthChange]);

  useEffect(() => {
    console.log('User state changed:', user);
    if (user) {
      let countryCode = location.country_code || 'ca';
      console.log('User already signed in, redirecting to:', `/${countryCode}`);
      navigate(`/${countryCode}`);
    }
  }, [user, location, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting sign-in form');
    try {
      console.log('Attempting to sign in with:', formData.email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
        options: {
          rememberMe: keepLoggedIn
        }
      });
      if (error) {
        console.error('Supabase sign-in error:', error);
        throw error;
      }
      console.log('Sign-in successful:', data);
    } catch (error) {
      console.error('Sign-in error:', error);
      if (error.message) {
        toast.error(`Sign-in failed: ${error.message}`, { id: 'signin-error-toast' });
      } else {
        toast.error('Sign-in failed. Please check your credentials and try again.', { id: 'signin-error-toast' });
      }
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center py-12 px-6 lg:px-8" style={{ backgroundColor: '#FDCD5E' }}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <div className="text-center">
            <Link to="/" className="block mx-auto mb-6">
              <img 
                src="https://cdn.prod.website-files.com/65f70e175676e7203e7230f6/66a7929b9a2f56f7aa461b62_ag2-png-full-p-500.png"
                alt="Alternative Grocers Logo"
                className="h-10 w-auto mx-auto"
              />
            </Link>
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="mt-6 space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="keepLoggedIn"
                  name="keepLoggedIn"
                  type="checkbox"
                  checked={keepLoggedIn}
                  onChange={(e) => setKeepLoggedIn(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label htmlFor="keepLoggedIn" className="ml-2 block text-sm text-gray-900">
                  Keep me logged in
                </label>
              </div>
              <div className="text-sm leading-6">
                <Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <Link to="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Sign up now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
