import React from 'react';
import Announcement from '../components/Announcement';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MarketingSection from '../components/MarketingSection';

const Hero = () => (
  <div className="bg-gray-50">
    <div className="mx-auto px-4 sm:px-6 lg:px-8 py-12 lg:py-16" style={{ maxWidth: '97rem' }}>
      <div className="flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl leading-tight">
            <span className="block">Supporting Local Indie Growers & Grocers</span>
          </h1>
          <p className="mt-3 text-gray-500 text-base" style={{ fontSize: '1rem', maxWidth: '38rem' }}>
          Helping small farmers and grocers connect and engage with their communities. Discover independent markets, recommend your favorites, and join us in building stronger local food networks—powered by you!
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:w-1/2">
          <img
            className="w-full rounded-lg shadow-xl"
            src="https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80"
            alt="Farmer's market with fresh produce"
          />
        </div>
      </div>
    </div>
  </div>
);

function PublicHomepage() {
  return (
    <div className="bg-white">
      <Announcement />
      <Header />
      <main>
        <Hero />
        <MarketingSection />
      </main>
      <Footer 
        key={Date.now()} // This will force a re-render of the Footer component
        customStyle={{ 
          maxWidth: '97rem', 
          margin: '0 auto'
        }} 
        textAlignLeft={true}
      />
    </div>
  );
}

export default PublicHomepage;
