import React, { useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faShare, faHeart } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';

function Place({ place, onPlaceClick, onPhotoClick, isFavorite, addFavorite, removeFavorite, isSignedIn, onShare }) {
  const [imgSrc, setImgSrc] = useState(
    place.photo || 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp'
  );

  const location = useLocation();

  const handleImageError = () => {
    setImgSrc('https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp');
  };

  const handlePlaceClick = () => {
    onPlaceClick(place);
  };

  const toggleFavorite = useCallback((e) => {
    e.stopPropagation();
    if (isSignedIn) {
      const action = isFavorite ? removeFavorite : addFavorite;
      const message = isFavorite
        ? `Removed ${place.name} from favorites.`
        : `Added ${place.name} to favorites!`;
      
      toast(message, {
        icon: isFavorite ? '💔' : '❤️',
        duration: 1500,
      });

      action(isFavorite ? place.id : place).catch(error => {
        console.error('Error toggling favorite:', error);
        toast.error('Failed to update favorites. Please try again.');
      });
    } else {
      toast.error('Please sign in to add favorites.', { duration: 2000 });
    }
  }, [isFavorite, place, addFavorite, removeFavorite, isSignedIn]);

  const handleShare = (e) => {
    e.stopPropagation();
    onShare(place);
  };

  const renderPlaceName = () => {
    const countryCode = window.location.pathname.split('/')[1];
    const province = (place.province || 'unknown').toLowerCase();
    const identifier = place.slug || place.id;
    const searchParams = new URLSearchParams(location.search);
    const city = searchParams.get('city');
    const category = searchParams.get('category');
    const subCategory = searchParams.get('subCategory');

    const state = {
      province,
      city,
      category,
      subCategory
    };

    const nameContent = (
      <span className="text-[1rem] font-semibold mb-2">
        {place.name}
      </span>
    );

    return isSignedIn ? (
      <Link
        to={`/${countryCode}/${province}/place/${identifier}`}
        state={state}
        className="hover:underline cursor-pointer"
        onClick={(e) => e.stopPropagation()}
      >
        {nameContent}
      </Link>
    ) : (
      nameContent
    );
  };

  return (
    <div
      className="place-card bg-white overflow-hidden flex flex-col cursor-pointer"
      onClick={handlePlaceClick}
      style={{ 
        width: '100%', 
        maxWidth: window.innerWidth <= 768 ? 'none' : '18.75rem', 
        margin: '0 auto', 
        boxShadow: 'none', 
        height: '100%' 
      }}
    >
      <div className="relative mb-3">
        <img
          src={imgSrc}
          alt={place.name}
          className="w-full h-[266px] object-cover rounded-lg border border-gray-300"
          onError={handleImageError}
          onClick={(e) => {
            e.stopPropagation();
            onPhotoClick(place);
          }}
        />
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 ease-in-out rounded-lg"></div>
        {isSignedIn && (
          <button
            onClick={toggleFavorite}
            className="absolute top-2 right-2 bg-white bg-opacity-70 rounded-full w-8 h-8 flex items-center justify-center transition-colors duration-200"
            aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
          >
            <FontAwesomeIcon 
              icon={faHeart} 
              className={`w-5 h-5 ${isFavorite ? 'text-red-500' : 'text-gray-400'} transition-colors duration-200`} 
            />
          </button>
        )}
        <button
          onClick={handleShare}
          className="absolute top-2 left-2 bg-white bg-opacity-70 rounded-full w-8 h-8 flex items-center justify-center"
          aria-label="Share"
        >
          <FontAwesomeIcon 
            icon={faShare} 
            className="w-5 h-5 text-gray-600"
          />
        </button>
      </div>
      <div className="flex flex-col flex-grow px-0.3 pr-0.5">
        <p className="text-gray-700 text-[0.875rem] font-normal mb-1">
          {place.category}
          {place.sub_category && (
            <>
              <FontAwesomeIcon icon={faChevronRight} className="mx-1 text-xs font-normal" />
              {place.sub_category}
            </>
          )}
        </p>
        {renderPlaceName()}
        <p className="text-gray-700 text-[0.875rem] mb-2">{place.address}</p>
      </div>
      {isSignedIn && (
        <div className="px-0.3 pr-0.5 pb-4">
          <div className="flex space-x-4 mt-2">
            {place.facebook && (
              <a href={place.facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook" className="text-gray-600 hover:text-blue-600" data-tooltip="Visit Facebook page" onClick={(e) => e.stopPropagation()}>
                <i className="fa-brands fa-square-facebook"></i>
              </a>
            )}
            {place.instagram && (
              <a href={place.instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-gray-600 hover:text-pink-600" data-tooltip="Visit Instagram profile" onClick={(e) => e.stopPropagation()}>
                <i className="fab fa-instagram"></i>
              </a>
            )}
            {place.website && (
              <a href={place.website} target="_blank" rel="noopener noreferrer" aria-label="Website" className="text-gray-600 hover:text-blue-600" data-tooltip="Visit website" onClick={(e) => e.stopPropagation()}>
                <i className="fa-sharp fa-light fa-globe-pointer"></i>
              </a>
            )}
            {place.email && (
              <a href={`mailto:${place.email}`} aria-label="Email" className="text-gray-600 hover:text-red-600" data-tooltip="Send email" onClick={(e) => e.stopPropagation()}>
                <i className="fa-sharp fa-light fa-envelope"></i>
              </a>
            )}
            {place.phone && (
              <a href={`tel:${place.phone}`} aria-label="Phone" className="text-gray-600 hover:text-green-600" data-tooltip="Call phone number" onClick={(e) => e.stopPropagation()}>
                <i className="fa-sharp fa-light fa-phone"></i>
              </a>
            )}
            {place.google_url && (
              <a href={place.google_url} target="_blank" rel="noopener noreferrer" aria-label="Google Maps" className="text-gray-600 hover:text-green-600" data-tooltip="View on Google Maps" onClick={(e) => e.stopPropagation()}>
                <i className="fa-sharp fa-light fa-circle-location-arrow"></i>
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Place;
