import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faHeart, faShare, faWifi, faTv, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Share from '../components/Share';
import { useFavorites } from '../context/FavoritesContext';
import { supabase } from '../config/supabaseClient';

const PlaceProfile = () => {
  const { countryCode, province, identifier } = useParams();
  const [place, setPlace] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showShareModal, setShowShareModal] = useState(false);
  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const [isFavorite, setIsFavorite] = useState(false);
  const fetchedRef = useRef(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchPlace = async () => {
      if (fetchedRef.current) return;
      fetchedRef.current = true;

      console.log('Fetching place with identifier:', identifier);
      if (!identifier) {
        console.error('No identifier provided');
        setError("No identifier provided");
        return;
      }

      try {
        let query = supabase
          .from('places')
          .select('id, name, slug, address, city, province, country, category, sub_category, photo, google_url, phone, email, website, facebook, instagram');

        // Check if the identifier is a number (id) or string (slug)
        if (isNaN(identifier)) {
          query = query.eq('slug', identifier);
        } else {
          query = query.eq('id', identifier);
        }

        const { data, error } = await query.single();

        if (error) throw error;

        if (data) {
          console.log('Place data fetched:', data);
          setPlace(data);
        } else {
          setError("Place not found");
        }
      } catch (error) {
        console.error('Error fetching place data:', error);
        setError("Failed to fetch place data");
      }
    };

    fetchPlace();
  }, [identifier]);

  useEffect(() => {
    if (place) {
      setIsFavorite(favorites.some(fav => fav.id === place.id));
    }
  }, [favorites, place]);

  const handleBackClick = () => {
    navigate(-1); // This will take the user to the previous page
  };

  const toggleFavorite = () => {
    if (!place) return;
    if (isFavorite) {
      removeFavorite(place.id);
    } else {
      addFavorite(place);
    }
    setIsFavorite(!isFavorite);
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const defaultImage = 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp';

  const images = [
    place?.photo || defaultImage,
    defaultImage,
    defaultImage,
    defaultImage,
    defaultImage,
  ];

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const renderAmenity = (amenity, index) => {
    let icon, name;
    if (typeof amenity === 'string') {
      icon = faWifi; // Default icon
      name = amenity;
    } else if (typeof amenity === 'object') {
      icon = amenity.icon || faWifi;
      name = amenity.name;
    }

    return (
      <div key={index} className="flex items-center">
        <FontAwesomeIcon icon={icon} className="mr-2" />
        <span>{name}</span>
      </div>
    );
  };

  if (error) {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h1 className="text-3xl font-bold mb-4">Error</h1>
          <p>{error}</p>
          <button onClick={handleBackClick} className="mt-4 text-blue-600 hover:text-blue-800">
            Go Back
          </button>
        </div>
        <Footer />
      </>
    );
  }

  if (!place) {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h1 className="text-3xl font-bold mb-4">Loading...</h1>
          <p>Identifier: {identifier}</p>
          <p>Country Code: {countryCode}</p>
          <p>Province: {province}</p>
        </div>
        <Footer />
      </>
    );
  }

  const locationString = [place.city, place.province, place.country]
    .filter(Boolean)
    .map(str => str.charAt(0).toUpperCase() + str.slice(1))
    .join(', ');

  return (
    <>
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <button onClick={handleBackClick} className="mb-4 flex items-center text-blue-600 hover:text-blue-800">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Go Back
        </button>
        <h1 className="text-2xl sm:text-3xl font-bold mb-4">{place.name}</h1>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div className="mb-2 sm:mb-0">
            <span className="text-sm">
              <FontAwesomeIcon icon={faStar} className="text-yellow-400 mr-1" />
              {/* TODO: Add rating and reviews when available */}
              {locationString}
            </span>
          </div>
          <div className="flex space-x-4">
            <button onClick={handleShare} className="text-blue-600 hover:text-blue-800">
              <FontAwesomeIcon icon={faShare} className="mr-2" /> Share
            </button>
            <button onClick={toggleFavorite} className="text-red-600 hover:text-red-800">
              <FontAwesomeIcon icon={faHeart} className="mr-2" color={isFavorite ? 'red' : 'gray'} /> {isFavorite ? 'Saved' : 'Save'}
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <div 
            className="cursor-pointer"
            onClick={() => openLightbox(0)}
          >
            <img 
              src={images[0]}
              alt={place.name} 
              className="w-full h-full object-cover rounded-lg border border-gray-300"
            />
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            {images.slice(1).map((image, index) => (
              <div 
                key={index + 1}
                className="cursor-pointer"
                onClick={() => openLightbox(index + 1)}
              >
                <img 
                  src={image}
                  alt={`${place.name} ${index + 2}`}
                  className="w-full h-full object-cover rounded-lg border border-gray-300"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              {place.category} - {place.sub_category}
            </h2>
            <p className="mb-4">
              {place.address}
            </p>
            <hr className="my-6" />
            <h3 className="text-lg sm:text-xl font-semibold mb-4">Contact Information</h3>
            {place.phone && <p className="mb-2">Phone: {place.phone}</p>}
            {place.email && <p className="mb-2">Email: {place.email}</p>}
            {place.website && <p className="mb-2">Website: <a href={place.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">{place.website}</a></p>}
            {place.facebook && <p className="mb-2">Facebook: <a href={place.facebook} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">{place.facebook}</a></p>}
            {place.instagram && <p className="mb-2">Instagram: <a href={place.instagram} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">{place.instagram}</a></p>}
            <hr className="my-6" />
            {/* TODO: Add amenities when available */}
            <h3 className="text-lg sm:text-xl font-semibold mb-4">What this place offers</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Placeholder amenities */}
              {renderAmenity({ icon: faWifi, name: 'Wifi' }, 0)}
              {renderAmenity({ icon: faTv, name: 'TV' }, 1)}
            </div>
          </div>
          <div>
            <div className="border rounded-lg p-6 shadow-lg mb-6">
              <div className="flex justify-between items-center mb-4">
                <span className="text-xl sm:text-2xl font-bold">
                  {/* TODO: Add price when available */}
                  Price not available
                </span>
              </div>
              {place.google_url && (
                <a href={place.google_url} target="_blank" rel="noopener noreferrer" className="w-full bg-pink-600 text-white py-2 px-4 rounded-md hover:bg-pink-700 inline-block text-center">
                  View on Google Maps
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showShareModal && (
        <Share
          url={window.location.href}
          onClose={() => setShowShareModal(false)}
        />
      )}
      {lightboxOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative max-w-4xl w-full">
            <img
              src={images[currentImageIndex]}
              alt={`${place.name} ${currentImageIndex + 1}`}
              className="max-h-[80vh] w-full object-contain"
            />
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full text-xl"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <button
              onClick={prevImage}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl"
            >
              &#8249;
            </button>
            <button
              onClick={nextImage}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl"
            >
              &#8250;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PlaceProfile;