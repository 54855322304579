import React from 'react';
import Layout from '../components/Layout';
import Announcement from '../components/Announcement';

function TermsOfService() {
  return (
    <>
      <Announcement />
      <Layout>
        <h1 className="text-3xl font-bold mb-6">AltGrocery Terms of Service</h1>
        <div className="prose max-w-none">
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing or using the AltGrocery website or any of our services, you agree to be bound by these Terms of Service ("Terms"), as well as our Privacy Policy. If you do not agree to these Terms, please discontinue use of our website and services immediately.</p>

          <h2>2. Use of Services</h2>
          <p>AltGrocery provides a platform for users to discover and recommend local independent grocers, markets, and farm stores. You agree to use our website and services for lawful purposes only and in compliance with all applicable laws and regulations.</p>

          <h2>3. User Accounts</h2>
          <p>To access certain features of our services, you may be required to create an account. You agree to:</p>
          <ul>
            <li>Provide accurate, current, and complete information.</li>
            <li>Maintain and promptly update your account information.</li>
            <li>Keep your password confidential and not share it with others.</li>
            <li>Accept responsibility for all activities that occur under your account.</li>
          </ul>
          <p>We reserve the right to suspend or terminate your account if we suspect any unauthorized use or activity that violates these Terms.</p>

          <h2>4. Prohibited Activities</h2>
          <p>You agree NOT to engage in any of the following activities while using our platform:</p>
          <ul>
            <li><strong>Data Scraping or Data Mining:</strong> You may not use automated tools (such as robots, spiders, or scrapers) to collect or harvest any data, content, or information from our website without our express permission.</li>
            <li><strong>Copying or Replicating Content:</strong> You may not copy, reproduce, or redistribute any content, photos, logos, business listings, or other proprietary information available on our website without prior written consent.</li>
            <li><strong>Contacting Businesses Without Permission:</strong> You may not use the information available on AltGrocery to contact any business listed on the platform for unsolicited marketing, sales, or other commercial purposes without our express written permission.</li>
            <li><strong>Misrepresentation:</strong> You may not impersonate others, misrepresent your identity, or falsely associate yourself with AltGrocery.</li>
            <li><strong>Unlawful or Harmful Conduct:</strong> You may not use the site in any manner that could damage, disable, or impair the site, or interfere with any other user's ability to use the site.</li>
          </ul>

          <h2>5. Intellectual Property</h2>
          <p>All content on AltGrocery, including but not limited to text, graphics, logos, icons, images, and software, is the exclusive property of AltGrocery or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. Unauthorized use of our content is strictly prohibited.</p>

          <h2>6. User-Generated Content</h2>
          <p>By submitting content, such as reviews, business recommendations, or other user-generated contributions to the site, you grant AltGrocery a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license to use, reproduce, modify, and display such content. You represent and warrant that you own or have the necessary rights to grant us these rights, and that your content does not violate any third-party rights.</p>

          <h2>7. Disclaimer of Warranties</h2>
          <p>AltGrocery is provided on an "AS IS" and "AS AVAILABLE" basis without any warranties of any kind, express or implied. We do not warrant that the website will be uninterrupted, secure, or error-free, or that any content or information provided is accurate, complete, or reliable. Use of the site and reliance on any information provided is at your own risk.</p>

          <h2>8. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, AltGrocery, its affiliates, employees, directors, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of data, profits, or business interruption arising from your use of or inability to use the site or services, even if we have been advised of the possibility of such damages.</p>

          <h2>9. Third-Party Links and Content</h2>
          <p>Our website may include links to third-party websites or services that are not owned or controlled by AltGrocery. We have no control over these sites and assume no responsibility for the content, privacy policies, or practices of any third-party sites. Your use of these third-party sites is at your own risk.</p>

          <h2>10. Indemnification</h2>
          <p>You agree to indemnify, defend, and hold harmless AltGrocery, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in any way connected with:</p>
          <ul>
            <li>Your access to or use of the services.</li>
            <li>Your violation of these Terms.</li>
            <li>Your violation of any rights of another party, including any business listed on our platform.</li>
          </ul>

          <h2>11. Termination</h2>
          <p>We reserve the right, at our sole discretion, to suspend or terminate your access to the site and services at any time and without notice, for any reason, including if we believe you have violated these Terms.</p>

          <h2>12. Governing Law</h2>
          <p>These Terms and your use of the website will be governed by and construed in accordance with the laws of the Province of Ontario, Canada, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located within Ontario for the resolution of any disputes.</p>

          <h2>13. Changes to These Terms</h2>
          <p>We may update these Terms from time to time. If we make material changes, we will notify you by updating the "Last Updated" date at the top of these Terms and, in some cases, provide you with additional notice. Your continued use of the website after such changes constitutes your acceptance of the new Terms.</p>

          <h2>14. Contact Us</h2>
          <p>If you have any questions about these Terms of Service, please contact us at:</p>
          <p>
            AltGrocery, Pixel Mixel Inc.<br />
            hello@altgrocery.ca<br />
            Erin, Ontario, Canada
          </p>
        </div>
      </Layout>
    </>
  );
}

export default TermsOfService;