import React from 'react';

function TagBadge({ label, onRemove }) {
  return (
    <div className="tag-badge">
      {label}
      <button onClick={onRemove}>&times;</button>
    </div>
  );
}

export default TagBadge;