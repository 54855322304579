import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useLocationContext } from '../context/LocationContext';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faHeart, faShare } from '@fortawesome/free-solid-svg-icons';
import CountryDetector from '../components/CountryDetector';
import { supabase } from '../config/supabaseClient';
import { useFavorites } from '../context/FavoritesContext';
import Share from '../components/Share';
import HomeNav from '../components/HomeNav';
import Footer from '../components/Footer';
import Announcement from '../components/Announcement';

const grocerTypes = [
  { name: "Farmers' Markets", image: "https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/ca/home/farmers-markets.webp" },
  { name: "Grocery Alternatives", image: "https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/ca/home/groceries.webp" },
  { name: "Pop-Up Produce Markets", image: "https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/ca/home/farm.webp" },
  { name: "Backyard Bounties", image: "https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/ca/home/backyard-bounty.webp" },
];

const PlaceCard = ({ place, country_code }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const province = (place.province || 'unknown').toLowerCase();
  const identifier = place.slug || place.id;

  const isFavorite = favorites.some(fav => fav.id === place.id);

  const toggleFavorite = (e) => {
    e.preventDefault();
    const action = isFavorite ? removeFavorite : addFavorite;
    const message = isFavorite
      ? `Removing ${place.name} from favorites...`
      : `Adding ${place.name} to favorites...`;

    toast.promise(
      action(isFavorite ? place.id : place),
      {
        loading: message,
        success: isFavorite
          ? `Removed ${place.name} from favorites.`
          : `Added ${place.name} to favorites!`,
        error: `Failed to ${isFavorite ? 'remove from' : 'add to'} favorites. Please try again.`,
      },
      {
        duration: 2000,
        style: {
          minWidth: '250px',
        },
        success: {
          style: {
            background: isFavorite ? '#EF4444' : '#10B981',
            color: '#FFFFFF',
          },
        },
      }
    );
  };

  const handleShare = (e) => {
    e.preventDefault();
    setShowShareModal(true);
  };

  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/${country_code}/${province}/place/${identifier}`;
  };

  return (
    <div className="relative flex-shrink-0 w-60 sm:w-72 md:w-80 lg:w-64 xl:w-[calc(16.666%-1rem)]">
      <div className="w-full h-[266px] rounded-lg overflow-hidden border border-gray-300 relative">
        <img 
          src={place.photo} 
          alt={place.name} 
          className="w-full h-full object-cover"
          loading="lazy"
        />
        <button 
          onClick={toggleFavorite}
          className="absolute top-2 right-2 bg-white bg-opacity-70 rounded-full w-8 h-8 flex items-center justify-center"
        >
          <FontAwesomeIcon 
            icon={faHeart} 
            className={`w-5 h-5 ${isFavorite ? 'text-red-500' : 'text-gray-400'}`} 
          />
        </button>
        <button 
          onClick={handleShare}
          className="absolute top-2 left-2 bg-white bg-opacity-70 rounded-full w-8 h-8 flex items-center justify-center"
        >
          <FontAwesomeIcon 
            icon={faShare} 
            className="w-5 h-5 text-gray-600"
          />
        </button>
      </div>
      <div className="mt-2">
        <div className="font-semibold text-base">
          <Link to={`/${country_code}/${province}/place/${identifier}`} className="hover:underline">
            {place.name}
          </Link>
        </div>
        <div className="text-sm text-gray-600">
          <div>{place.address}</div>
          <div>{place.city}</div>
        </div>
      </div>
      {showShareModal && (
        <Share
          url={getShareUrl()}
          onClose={() => setShowShareModal(false)}
        />
      )}
    </div>
  );
};

const ProvinceCard = ({ province, country_code }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const formattedProvinceName = province.name.replace(/\s+/g, '+');
    navigate(`/${country_code}/map?province=${formattedProvinceName}`);
  };

  return (
    <div
      className="relative flex-shrink-0 w-60 sm:w-72 md:w-80 lg:w-64 xl:w-[calc(16.666%-1rem)] cursor-pointer"
      onClick={handleCardClick}
    >
      <img
        src={province.photo}
        alt={province.name}
        className="w-full h-[266px] bg-gray-300 rounded-lg object-cover hover:opacity-75 transition-opacity duration-200"
        loading="lazy"
      />
      <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 px-3 py-2 rounded-b-lg">
        <div className="text-white text-[0.9rem] font-medium truncate">{province.name}</div>
      </div>
    </div>
  );
};

const Carousel = ({ children, isPlaceCarousel = false }) => {
  const carouselRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollability = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('scroll', checkScrollability);
      window.addEventListener('resize', checkScrollability);
      
      checkScrollability();
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('scroll', checkScrollability);
        window.removeEventListener('resize', checkScrollability);
      }
    };
  }, []);

  useEffect(() => {
    checkScrollability();
  }, [children]);

  const scroll = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.clientWidth * 0.8;
      carouselRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const chevronClass = `carousel-chevron absolute z-[10] top-1/2 transform -translate-y-1/2 bg-white bg-opacity-80 rounded-full p-2 shadow-md ${
    isPlaceCarousel ? 'place-carousel-chevron' : ''
  }`;

  return (
    <div className="carousel-container relative">
      <button
        className={`${chevronClass} left-2 ${canScrollLeft ? 'opacity-100' : 'opacity-50 cursor-not-allowed'}`}
        onClick={() => canScrollLeft && scroll('left')}
        disabled={!canScrollLeft}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="text-gray-800 w-6 h-6" />
      </button>
      <div className="carousel-content overflow-hidden">
        <div
          ref={carouselRef}
          className="flex overflow-x-auto space-x-4 pb-4 custom-scrollbar"
        >
          {children}
        </div>
      </div>
      <button
        className={`${chevronClass} right-2 ${canScrollRight ? 'opacity-100' : 'opacity-50 cursor-not-allowed'}`}
        onClick={() => canScrollRight && scroll('right')}
        disabled={!canScrollRight}
      >
        <FontAwesomeIcon icon={faChevronRight} className="text-gray-800 w-6 h-6" />
      </button>
    </div>
  );
};

const WeeklyCard = ({ type }) => (
  <div className="relative flex-shrink-0 w-[85vw] sm:w-[45vw] md:w-[30vw] lg:w-[23vw]">
    <div className="w-full h-72 bg-gray-300 rounded-xl relative overflow-hidden">
      <img src={type.image} alt={type.name} className="w-full h-full object-cover" loading="lazy" />
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      <div className="absolute bottom-6 left-6 text-white">
        <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
        <button className="bg-white text-black px-4 py-2 rounded-lg text-sm font-semibold">Coming Soon</button>
      </div>
    </div>
  </div>
);

const Section = ({ title, items, type, country_code }) => (
  <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8 py-8">
    <h2 className="text-xl font-semibold mb-6">{title}</h2>
    <Carousel isPlaceCarousel={type !== "province"}>
      {items.map((item, index) => (
        type === "province" ? <ProvinceCard key={index} province={item} country_code={country_code} /> :
        <PlaceCard key={index} place={item} country_code={country_code} />
      ))}
    </Carousel>
  </div>
);

const getRandomPlaces = (places, count) => {
  const shuffled = [...places].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const getStoredPlaces = (key) => {
  const stored = localStorage.getItem(key);
  return stored ? JSON.parse(stored) : null;
};

const setStoredPlaces = (key, places) => {
  localStorage.setItem(key, JSON.stringify(places));
};

export default function Homepage() {
  const { user } = useAuth();
  const [provinces, setProvinces] = useState([]);
  const navigate = useNavigate();
  const { location } = useLocationContext();
  const [featuredPlaces, setFeaturedPlaces] = useState(() => getStoredPlaces('featuredPlaces') || []);
  const [popularPlaces, setPopularPlaces] = useState(() => getStoredPlaces('popularPlaces') || []);
  const [topSellers, setTopSellers] = useState(() => getStoredPlaces('topSellers') || []);
  const [sustainableGrocers, setSustainableGrocers] = useState(() => getStoredPlaces('sustainableGrocers') || []);
  const [localFavorites, setLocalFavorites] = useState(() => getStoredPlaces('localFavorites') || []);
  const [isLoading, setIsLoading] = useState(() => {
    return localStorage.getItem('initialLoadComplete') !== 'true';
  });

  const fetchAndSetPlaces = useCallback(async () => {
    const countryCode = location.country_code || 'ca';

    try {
      if (isLoading) {
        console.log('Fetching data for country code:', countryCode);

        // Check Supabase connection
        const { error: connectionError } = await supabase.from('places').select('id').limit(1);
        if (connectionError) {
          console.error('Error connecting to Supabase:', connectionError);
          throw new Error('Failed to connect to the database');
        }
        console.log('Supabase connection successful');

        if (countryCode.toLowerCase() === 'ca') {
          const { data: provincesData, error: provincesError } = await supabase
            .from('provinces')
            .select('name, photo')
            .eq('country_code', countryCode);

          if (provincesError) {
            console.error('Error fetching provinces:', provincesError);
            throw provincesError;
          }

          console.log('Fetched provinces:', provincesData);

          const sortedProvinces = provincesData.sort((a, b) => a.name.localeCompare(b.name));
          setProvinces(sortedProvinces);
        }

        const { data: placesData, error: placesError } = await supabase
          .from('places')
          .select('id, name, address, city, photo, slug, province')
          .neq('photo', 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/ca/default-shop.jpg');

        if (placesError) {
          console.error('Error fetching places:', placesError);
          throw placesError;
        }

        console.log('Fetched places:', placesData);

        const newFeaturedPlaces = getRandomPlaces(placesData, 10);
        setFeaturedPlaces(newFeaturedPlaces);
        setStoredPlaces('featuredPlaces', newFeaturedPlaces);

        const newPopularPlaces = getRandomPlaces(placesData, 10);
        setPopularPlaces(newPopularPlaces);
        setStoredPlaces('popularPlaces', newPopularPlaces);

        const newTopSellers = getRandomPlaces(placesData, 10);
        setTopSellers(newTopSellers);
        setStoredPlaces('topSellers', newTopSellers);

        const newSustainableGrocers = getRandomPlaces(placesData, 10);
        setSustainableGrocers(newSustainableGrocers);
        setStoredPlaces('sustainableGrocers', newSustainableGrocers);

        const newLocalFavorites = getRandomPlaces(placesData, 10);
        setLocalFavorites(newLocalFavorites);
        setStoredPlaces('localFavorites', newLocalFavorites);

        localStorage.setItem('initialLoadComplete', 'true');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load some content. Please refresh the page.');
    } finally {
      setIsLoading(false);
    }
  }, [location.country_code, isLoading]);

  useEffect(() => {
    if (!user) {
      navigate('/signin');
      return;
    }

    fetchAndSetPlaces();
  }, [user, navigate, fetchAndSetPlaces]);

  useEffect(() => {
    // Clear stored places when user logs out
    return () => {
      if (!user) {
        localStorage.removeItem('featuredPlaces');
        localStorage.removeItem('popularPlaces');
        localStorage.removeItem('topSellers');
        localStorage.removeItem('sustainableGrocers');
        localStorage.removeItem('localFavorites');
        localStorage.removeItem('initialLoadComplete');
      }
    };
  }, [user]);

  if (!user) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="text-2xl font-semibold">Loading...</div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-screen">
      <Announcement />
      <CountryDetector />
      <HomeNav />

      <main className='pt-2'>
        <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8 py-8">
          <h2 className="text-xl font-semibold mb-6">
            New this month {location.countryName ? `in ${location.countryName.charAt(0).toUpperCase() + location.countryName.slice(1)}` : ''}
          </h2>
          <Carousel>
            {grocerTypes.map((type, index) => (
              <WeeklyCard key={index} type={type} />
            ))}
          </Carousel>
        </div>

        {location.countryName.toLowerCase() === 'canada' && provinces.length > 0 && (
          <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8 py-8">
            <h2 className="text-xl font-semibold mb-6">Explore Provinces in Canada</h2>
            <Carousel>
              {provinces.map((province, index) => (
                <ProvinceCard key={index} province={province} country_code={location.country_code || 'ca'} />
              ))}
            </Carousel>
          </div>
        )}

        <Section title="Featured Places" items={featuredPlaces} type="grocer" country_code={location.country_code || 'ca'} />
        <Section title="Popular Alternative Grocers" items={popularPlaces} type="grocer" country_code={location.country_code || 'ca'} />
        <Section title="Top Sellers" items={topSellers} type="grocer" country_code={location.country_code || 'ca'} />
        <Section title="Sustainable Grocers" items={sustainableGrocers} type="grocer" country_code={location.country_code || 'ca'} />
        <Section title="Local Favorites" items={localFavorites} type="grocer" country_code={location.country_code || 'ca'} />
      </main>

      <Footer 
        customStyle={{ 
          maxWidth: '97rem', 
          margin: '0 auto'
        }} 
        textAlignLeft={true}
      />
    </div>
  );  
}

const styles = `
  .place-carousel-chevron {
    top: 36%;
  }
  .carousel-chevron {
    z-index: 10;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .carousel-chevron:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const styleElement = document.createElement('style');
styleElement.textContent = styles;
document.head.appendChild(styleElement);